import { AxiosResponse } from 'axios';
import api from './api';
import {
	AddUser,
	EditUser,
	GetProjectUsers,
	User,
} from '../containers/User/UserAssets';
import { UserQueryParams } from '../interfaces/UserQueryParams';

interface GetUsers {
	data: User[];
	count: number;
}

type SaveUser = AddUser | EditUser;

export const getUsers = (params: UserQueryParams): Promise<AxiosResponse<GetUsers>> => api.get<GetUsers>('/user', { params });

export const getActiveUsersWithoutPagination = (): Promise<AxiosResponse<GetUsers>> => api.get<GetUsers>('/user/activeWithoutPagination');

export const getActiveUsersWithoutPaginationBP = (): Promise<AxiosResponse<GetUsers>> => api.get<GetUsers>('/user/activeWithoutPaginationBP');

export const getActiveUsersWithoutPaginationBPAdmin = (): Promise<AxiosResponse<GetUsers>> => api.get<GetUsers>('/user/activeWithoutPaginationBPAdmin');

export const getActiveUsersWithProjectTaskStatus = (): Promise<AxiosResponse<GetProjectUsers>> => api.get<GetProjectUsers>('/user/activeUsersWithProjectTaskStatus');

export const getUserById = (id: string): Promise<AxiosResponse<User>> => api.get<User>(`user/${id}`);

export const saveUser = (data: SaveUser): Promise<AxiosResponse<User & { message: string }>> => api.post<SaveUser, AxiosResponse<User & { message: string }>>('/user', data);

export const updateUser = (id: string, data: EditUser): Promise<AxiosResponse<User & { message: string }>> => api.patch<EditUser, AxiosResponse<User & { message: string }>>(`/user/${id}`, data);

export const getUserPermissions = (): Promise<AxiosResponse> => api.get('user/permissions');

export const changePassword = (
	id: string,
	password: string,
	changePasswordLogin: boolean,
): Promise<AxiosResponse<{ message: string }>> => {
	const data = { id, password, changePasswordLogin };
	return api.post<{ message: string }>('/user/changePassword', data);
};

export const handleUserActiveStatus = (
	id: string,
	active: boolean,
): Promise<AxiosResponse<{ message: string, data: User }>> => {
	const data = { id, active };
	return api.post<{ message: string, data: User }>('/user/handleActive', data);
};
