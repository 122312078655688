import {
	OptionsObject,
	SnackbarKey,
	SnackbarMessage,
	useSnackbar,
} from 'notistack';
import React, {
	Dispatch,
	Reducer,
	useMemo,
	useReducer,
} from 'react';
import { AxiosError } from 'axios';
import { NavigateFunction, useNavigate } from 'react-router-dom';
import BranchEdit from '../../components/Branch/BranchEdit';
import { IBranch } from './BranchAssets';
import {
	getBranchById, updateBranch, saveBranch,
} from '../../services/branch';
import { getCompanies } from '../../services/company';
import { ICompany } from '../Company/CompanyAssets';

enum ActionType {
	LOADING,
	BRANCH,
	COMPANY,
}
export interface IState {
	loading: boolean;
	branch?: IBranch;
	companies: ICompany[];
}
type TAction =
	| { type: ActionType.LOADING; payload: { loading: boolean } }
	| { type: ActionType.BRANCH; payload: { branch: IBranch } }
	| { type: ActionType.COMPANY; payload: { companies: ICompany[] } };
export interface IBranchActions {
	setLoading(loading: boolean): void;
	setBranch(branch: IBranch): void;
	getCompanies(): void;
	handleEditBranch: (params: {
		id?: string;
		data: FormData;
	}, branch?: IBranch) => void;
	getBranchById(id: string, companyId: string): void;
}
const initialState: IState = {
	loading: false,
	branch: undefined,
	companies: [],
};
const reducer: Reducer<IState, TAction> = (state, action) => {
	switch (action.type) {
		case ActionType.LOADING:
			return { ...state, loading: action.payload.loading };
		case ActionType.BRANCH:
			return { ...state, branch: action.payload.branch };
		case ActionType.COMPANY:
			return { ...state, companies: action.payload.companies };
		default:
			throw new Error();
	}
};

const BranchActions = (
	dispatch: Dispatch<TAction>,
	enqueueSnackbar: (message: SnackbarMessage, options?: OptionsObject | undefined) => SnackbarKey,
	navigate: NavigateFunction,
): IBranchActions => {
	const actions = {
		setLoading(loading: boolean) {
			dispatch({ type: ActionType.LOADING, payload: { loading } });
		},
		setBranch(branch: IBranch) {
			dispatch({ type: ActionType.BRANCH, payload: { branch } });
		},
		handleEditBranch(values: {
			id?: string;
			data: FormData;
		}): void {
			actions.setLoading(true);
			const { id, data } = values;

			if (id) {
				updateBranch(id, data)
					.then((response) => {
						navigate('/branch');
						enqueueSnackbar(response.data.message, { variant: 'success' });
					})
					.catch((error: AxiosError) => {
						enqueueSnackbar(error.response?.data.message || 'Algum erro ocorreu, tente novamente ou contate um administrador.', {
							variant: 'error',
						});
						actions.setLoading(false);
					});
			} else {
				saveBranch(data)
					.then((response) => {
						navigate('/branch');
						enqueueSnackbar(response.data.message, { variant: 'success' });
					})
					.catch((error: AxiosError) => {
						enqueueSnackbar(error.response?.data.message || 'Algum erro ocorreu, tente novamente ou contate um administrador.', {
							variant: 'error',
						});
						actions.setLoading(false);
					});
			}
		},
		getBranchById(id: string, companyId: string) {
			actions.setLoading(true);
			getBranchById(id, companyId)
				.then((response) => {
					if (!response.data) {
						enqueueSnackbar('Filial não encontrada.', {
							variant: 'error',
						});
						navigate('/branch');
					}
					actions.setBranch(response.data);
					actions.setLoading(false);
				})
				.catch(() => {
					actions.setLoading(false);
				});
		},
		getCompanies() {
			dispatch({ type: ActionType.LOADING, payload: { loading: true } });
			getCompanies().then((response) => {
				dispatch({
					type: ActionType.COMPANY,
					payload: {
						companies: response.data.data,
					},
				});
				dispatch({ type: ActionType.LOADING, payload: { loading: false } });
			});
		},
	};
	return actions;
};

const BranchEditContainer = (): JSX.Element => {
	const [state, dispatch] = useReducer<Reducer<IState, TAction>>(reducer, initialState);
	const { enqueueSnackbar } = useSnackbar();
	const navigate = useNavigate();
	const actions = useMemo(
		() => BranchActions(dispatch, enqueueSnackbar, navigate),
		[enqueueSnackbar, navigate],
	);
	// eslint-disable-next-line react/jsx-props-no-spreading
	return <BranchEdit {...state} {...actions} />;
};
export default BranchEditContainer;
