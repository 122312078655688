import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { AddUser, EditUser, User } from '../../containers/User/UserAssets';
import UserForm from './UserForm';
import { Loading } from '../Common/Loading';

interface UserEditProps {
	loading: boolean;
	user?: User;
	getUserById: (id: string) => void;
	handleEditUser: (values: { id?: string; data: AddUser | EditUser }) => void;
}

const UserEdit = ({
	loading, handleEditUser, getUserById, user,
}: UserEditProps): JSX.Element => {
	const { id } = useParams();

	useEffect(() => {
		if (id) {
			getUserById(id);
		}
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		loading
			? <Loading />
			: <UserForm loading={loading} handleEditUser={handleEditUser} user={user} />
	);
};

UserEdit.defaultProps = {
	user: undefined,
};

export default UserEdit;
