import React from 'react';
import { useFormik } from 'formik';
import {
	Box, Button, Grid, IconButton, Stack, Typography,
} from '@mui/material';
import { Add, Remove } from '@mui/icons-material';
import Input from '../../Common/Form/Input';

export enum StepsInventory {
    PRODUCT,
	LOCATION
}

const initialValues = {
	productBarCode: '',
	activeStep: StepsInventory.PRODUCT,
};

interface InventoryStartProps {
	handleFinish: () => void;
	secondConference: boolean;
}

const InventoryQuantity = ({
	handleFinish,
	secondConference,
}: InventoryStartProps):JSX.Element => {
	const formik = useFormik({
		initialValues,
		onSubmit: () => {
			console.log('tasksPendingPost');
		},
	});

	return (
		<Box padding={1}>
			<Typography variant="subtitle1" align="center">
				{'Localização: '}
			</Typography>
			<Typography variant="subtitle1" align="center" sx={{ marginBottom: 2 }}>
				{'Pedido de Venda: '}
			</Typography>
			<Box
				sx={{
					padding: 2, border: 1, borderColor: '#bbb', bgcolor: 'white', borderRadius: 2, mb: 2, display: 'flex', justifyContent: 'space-between',
				}}
			>
				<Stack direction="column">
					<Typography variant="subtitle1" align="center" fontWeight="bold">
						30009 - MATA FORMIGA
					</Typography>
					<Grid container spacing={2}>
						<Grid item xs={12} sm={6}>
							<Input.InputField
								label="Código de Barras"
								id="barcodeProduct"
								name="barcodeProduct"
								fullWidth
							/>
						</Grid>
						<Grid item xs={12} sm={6}>
							<Box
								sx={{
									width: '100%',
									display: 'flex',
									alignItems: 'center',
									justifyContent: 'center',
								}}
							>
								<IconButton size="large">
									<Remove />
								</IconButton>

								<Input.InputField
									id="productQuantity"
									name="productQuantity"
									style={{ width: 100, textAlign: 'center' }}
									type="number"
									required
								/>
								<IconButton size="large">
									<Add />
								</IconButton>
							</Box>
						</Grid>
						<Grid item xs={12} sm={6} display="flex" justifyContent="space-between" marginTop={2} gap={2}>
							<Button
								variant="contained"
								size="small"
								color="primary"
								sx={{ width: '100%' }}
								onClick={handleFinish}
							>
								Confirmar
							</Button>
						</Grid>
					</Grid>
				</Stack>
			</Box>
		</Box>
	);
};

export default InventoryQuantity;
