import React, { useCallback, useEffect } from 'react';
import { Paper, Typography, Theme } from '@mui/material';
import { SxProps } from '@mui/system';
import { useParams } from 'react-router-dom';
import LocationTypeForm from './LocationTypeForm';
import { ILocationTypeActions } from '../../containers/LocationType/LocationTypeEdit';
import { ILocationType } from '../../containers/Location/LocationAssets';

interface LocationTypeEditProps extends ILocationTypeActions {
  locationType?: ILocationType;
  loading: boolean;
  locations: Array<ILocationType>;
}

const paperSx: SxProps<Theme> = {
	mt: 3,
	p: 3,
};

const LocationTypeEdit = ({
	locationType,
	loading,
	locations,
	handleEditLocationType,
	getLocationTypeById,
	getLocationsState,
}: LocationTypeEditProps): JSX.Element => {
	const { id } = useParams();

	const handleSubmit = useCallback(
		(values: ILocationType) => {
			handleEditLocationType({
				id: locationType?.id,
				data: {
					level: Number(values.level),
					name: values.name,
					prefix: values.prefix.toUpperCase(),
				},
			});
		},
		[handleEditLocationType, locationType?.id],
	);

	useEffect(() => {
		getLocationsState();
		if (id) {
			getLocationTypeById(id);
		}
	}, []);

	return (
		<Paper sx={paperSx} elevation={3}>
			<Typography variant="h4" color="primary" gutterBottom>
				Cadastrar Tipo de Localização
			</Typography>
			<LocationTypeForm
				loading={loading}
				locations={locations}
				locationType={locationType}
				onSubmit={handleSubmit}
			/>
		</Paper>
	);
};

LocationTypeEdit.defaultProps = {
	locationType: undefined,
};

export default LocationTypeEdit;
