import React, {
	useCallback,
	useState,
} from 'react';
import {
	styled,
	Theme,
} from '@mui/material/styles';
import {
	SxProps,
} from '@mui/system';
import MuiAppBar from '@mui/material/AppBar';
import IconButton from '@mui/material/IconButton';
import Toolbar from '@mui/material/Toolbar';
import MenuIcon from '@mui/icons-material/Menu';
import {
	Autorenew,
	Home,
	Inbox,
	LocalShipping,
	LocationOn,
	Storage,
	TransferWithinAStation,
} from '@mui/icons-material';
import {
	Box,
	Drawer,
	List,
	ListItem,
	ListItemButton,
	ListItemIcon,
	ListItemText,
	Button,
	Divider,
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import {
	drawerWidth,
} from '../../constants/menu';
import { logout } from '../../services/auth';

interface StyledAppBarProps {
	theme: Theme;
	open?: boolean;
}

const navbar: SxProps<Theme> = {
	boxShadow: 0,
	backgroundColor: '#fff',
	borderBottom: '1px solid #e1e1e1',
};

const AppBar = styled(MuiAppBar, {
	shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }: StyledAppBarProps) => ({
	zIndex: theme.zIndex.drawer + 1,
	transition: theme.transitions.create(['width', 'margin'], {
		easing: theme.transitions.easing.sharp,
		duration: theme.transitions.duration.leavingScreen,
	}),
	...(open && {
		marginLeft: drawerWidth,
		width: `calc(100% - ${drawerWidth}px)`,
		transition: theme.transitions.create(['width', 'margin'], {
			easing: theme.transitions.easing.sharp,
			duration: theme.transitions.duration.enteringScreen,
		}),
	}),
	[theme.breakpoints.down('md')]: {
		width: '100%',
		marginLeft: 0,
	},
}));

const toolbar: SxProps<Theme> = {
	display: 'flex',
	justifyContent: 'space-between',
	alignItems: 'center',
	padding: (theme) => theme.spacing(1),
};

const Navbar = (): JSX.Element => {
	const [openDrawer, setOpenDrawer] = useState(false);

	const navigate = useNavigate();

	const toggleDrawer = (newOpen: boolean) => () => {
		setOpenDrawer(newOpen);
	};

	const handleMenu = (): void => {
		setOpenDrawer((prevState) => !prevState);
	};

	const handleLogout = useCallback(() => {
		setOpenDrawer(false);
		logout();
	}, []);

	return (
		<>
			<AppBar color="primary" sx={navbar}>
				<Toolbar sx={toolbar}>
					<Box sx={{ display: 'flex', alignItems: 'center' }}>
						<IconButton
							size="large"
							aria-label="menu"
							onClick={handleMenu}
						>
							<MenuIcon />
						</IconButton>
						<Box component="img" alt="Logo" src="/api/configuration/logo" sx={{ height: 'auto', width: '8rem' }} />
					</Box>
				</Toolbar>
			</AppBar>
			<Drawer open={openDrawer} onClose={toggleDrawer(false)}>
				<Box
					sx={{
						width: 250,
					}}
					role="presentation"
					onClick={toggleDrawer(false)}
				>
					<Box sx={{ mt: '64px' }}>
						<List>
							<ListItem disablePadding>
								<ListItemButton onClick={() => navigate('')}>
									<ListItemIcon>
										<Home />
									</ListItemIcon>
									<ListItemText>Início</ListItemText>
								</ListItemButton>
							</ListItem>
							<ListItem disablePadding>
								<ListItemButton onClick={() => navigate('storage')}>
									<ListItemIcon>
										<Storage />
									</ListItemIcon>
									<ListItemText>Armazenagem</ListItemText>
								</ListItemButton>
							</ListItem>
							<ListItem disablePadding>
								<ListItemButton onClick={() => navigate('expedition')}>
									<ListItemIcon>
										<LocationOn />
									</ListItemIcon>
									<ListItemText>Expedição</ListItemText>
								</ListItemButton>
							</ListItem>
							<ListItem disablePadding>
								<ListItemButton onClick={() => navigate('transfer')}>
									<ListItemIcon>
										<Autorenew />
									</ListItemIcon>
									<ListItemText>Transferência</ListItemText>
								</ListItemButton>
							</ListItem>
						</List>
					</Box>
					<Divider />
					<Box sx={{ padding: 2 }}>
						<Button variant="contained" onClick={handleLogout} fullWidth>
							Sair
						</Button>
					</Box>
				</Box>
			</Drawer>
		</>
	);
};

export default Navbar;
