/* eslint-disable react/jsx-props-no-spreading */
import React, { SyntheticEvent, useCallback } from 'react';
import { Form, Formik } from 'formik';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Close from '@mui/icons-material/Close';
import pick from 'lodash/pick';
import { IconButton, InputAdornment } from '@mui/material';
import { LocationProductQueryParams } from '../../interfaces/LocationProductQueryParams';
import Autocomplete from '../Common/Form/Autocomplete';
import { IProduct } from '../../containers/Product/ProductAssets';
import { ProductQueryParams } from '../../interfaces/ProductQueryParams';
import Input from '../Common/Form/Input';
import Select from '../Common/Form/Select';
import { availableFilterData } from '../../constants/location';

interface IFilterProps {
  loading: boolean;
  initialValues: LocationProductQueryParams;
  products: IProduct[];
  filter: LocationProductQueryParams;
  getProducts: (queryParams: ProductQueryParams) => void;
  sendFilter: (values: Partial<LocationProductQueryParams>) => void;
}

const LocationFilter = ({
	initialValues,
	loading,
	products,
	filter,
	sendFilter,
	getProducts,
}: IFilterProps): JSX.Element => {
	const onSubmit = useCallback(
		(values: LocationProductQueryParams) => {
			const newValueFilter: LocationProductQueryParams = {
				...values,
				locationValue: values.locationValue
					? values.locationValue.replace(/[^a-zA-Z0-9]/g, '')
					: undefined,
			};

			sendFilter(newValueFilter);
		},
		[sendFilter],
	);

	const onReset = useCallback(
		(handleReset) => {
			const newDataFilter = pick(filter, ['skip', 'take']);
			sendFilter(newDataFilter);
			handleReset();
		},
		[sendFilter, filter],
	);

	return (
		<Formik initialValues={initialValues} onSubmit={onSubmit}>
			{({ handleReset, values, setFieldValue }) => (
				<Form>
					<Grid container spacing={2} sx={{ p: 2, marginTop: 6 }}>
						<Grid item xs={12}>
							<Input.InputField
								id="locationValue"
								name="locationValue"
								label="Localizações"
								autoComplete="off"
								fullWidth
							/>
						</Grid>

						<Grid item xs={12}>
							<Autocomplete
								label="Produto"
								name="productIds"
								valueKey="id"
								valueLabel="code"
								labelKey="description"
								options={products}
								loading={loading}
								multiple
								onInputChange={(event: SyntheticEvent, value: string) => {
									if (value?.length > 2) {
										getProducts({ skip: 0, description: value });
									}
								}}
								fullWidth
							/>
						</Grid>

						<Grid item xs={12}>
							<Select
								name="available"
								label="Disponível"
								options={availableFilterData}
								valueKey="value"
								labelKey="label"
								required={false}
								size="medium"
								endAdornment={
									(values.available !== '' as any) ? (
										<InputAdornment sx={{ marginRight: '10px' }} position="end">
											<IconButton
												onClick={() => {
													setFieldValue('available', '');
												}}
											>
												<Close />
											</IconButton>
										</InputAdornment>
									) : null
								}
							/>
						</Grid>

						<Grid
							item
							xs={12}
							sx={{ display: 'flex', justifyContent: 'flex-end', gap: '1rem' }}
						>
							<Button variant="outlined" onClick={() => onReset(handleReset)}>
								Restaurar
							</Button>
							<Button variant="contained" type="submit">
								Filtrar
							</Button>
						</Grid>
					</Grid>
				</Form>
			)}
		</Formik>
	);
};

export default LocationFilter;
