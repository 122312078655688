/* eslint-disable react/jsx-props-no-spreading */
import React, { useCallback, useMemo } from 'react';
import { Form, Formik } from 'formik';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Input from '../Common/Form/Input';
import { InventoryTaskQueryParams } from '../../interfaces/InventoryTaskQueryParams';
import Select from '../Common/Form/Select';
import {
	defaultInventoryTaskQueryParams,
	drawerFilterInventoryPendingTask,
	inventoryTaskStatusFilter,
} from '../../constants/inventoryTask';
import { DatePicker } from '../Common/Form/DatePicker';
import { filterObject } from '../../helpers/Utils';
import { InventoryTaskAction } from '../../enums/InventoryTaskAction';

interface FilterProps {
	handlerValueFilter: (values: Partial<InventoryTaskQueryParams>) => void;
    initialValues: InventoryTaskQueryParams
}

const InventoryPendingTaskFilter = ({
	initialValues,
	handlerValueFilter,
}: FilterProps): JSX.Element => {
	const onSubmit = useCallback((values: Partial<InventoryTaskQueryParams>) => {
		localStorage.setItem('inventoryTaskFilterParams', JSON.stringify(values));
		handlerValueFilter(filterObject(values));
	}, [handlerValueFilter]);

	const onReset = useCallback((resetForm) => {
		handlerValueFilter(filterObject(defaultInventoryTaskQueryParams));
		localStorage.setItem(
			'inventoryTaskFilterParams',
			JSON.stringify(filterObject(defaultInventoryTaskQueryParams)),
		);
		resetForm();
	}, [handlerValueFilter]);

	const isSupplyOrDispath = useMemo(() => {
		const types = [InventoryTaskAction.DISPATCH, InventoryTaskAction.SUPPLY];
		return types.includes(initialValues.type as InventoryTaskAction);
	}, [initialValues.type]);

	return (
		<Formik
			initialValues={initialValues}
			validationSchema={drawerFilterInventoryPendingTask}
			onSubmit={onSubmit}
		>
			{({
				resetForm,
			}) => (
				<Form>
					<Grid container spacing={2} sx={{ p: 2, marginTop: 6 }}>
						{isSupplyOrDispath && (
							<Grid item xs={12}>
								<Input.InputField
									id="client"
									name="client"
									label="Fornecedor/Cliente"
									autoComplete="off"
									fullWidth
								/>
							</Grid>
						)}

						<Grid container spacing={2} item xs={12}>
							<Grid item xs={6}>
								<DatePicker
									name="startDate"
									label="Data início"
								/>
							</Grid>

							<Grid item xs={6}>
								<DatePicker
									name="endDate"
									label="Data Final"
								/>
							</Grid>
						</Grid>

						<Grid item xs={12}>
							<Input.InputField
								id="companyId"
								name="companyId"
								label="Empresa/Filial"
								autoComplete="off"
								fullWidth
							/>
						</Grid>

						{isSupplyOrDispath && (
							<Grid item xs={12}>
								<Input.InputField
									id="saleOrder"
									name="saleOrder"
									label="Pedido"
									autoComplete="off"
									fullWidth
								/>
							</Grid>
						)}

						<Grid item xs={12}>
							<Select
								name="status"
								label="Status"
								options={inventoryTaskStatusFilter}
								valueKey="value"
								labelKey="label"
								required={false}
								size="medium"
							/>
						</Grid>

						<Grid item xs={12} sx={{ display: 'flex', justifyContent: 'flex-end', gap: '1rem' }}>
							<Button variant="outlined" onClick={() => onReset(resetForm)}>
								Restaurar
							</Button>
							<Button variant="contained" type="submit">
								Filtrar
							</Button>
						</Grid>
					</Grid>
				</Form>
			)}
		</Formik>
	);
};

export default InventoryPendingTaskFilter;
