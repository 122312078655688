import React, { useMemo } from 'react';
import { Link } from 'react-router-dom';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import {
	DataGrid,
	GridActionsCellItem,
	GridColDef,
	GridRenderCellParams,
	GridValueFormatterParams,
} from '@mui/x-data-grid';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import { IBranch } from '../../containers/Branch/BranchAssets';
import { getBase64Image } from '../../helpers/Utils';
import { applyCnpjMask } from '../../helpers/masks';
import useConfirmationDialog from '../../hooks/useConfirmationDialog';

interface BranchTableProps {
	loading?: boolean;
	rows: IBranch[];
	handleDeleteBranch: (id: string, companyId: string) => void;
}

interface CustomValueFormatterParams extends GridValueFormatterParams<unknown> {
	value: number | string;
}

const BranchTable: React.FC<BranchTableProps> = ({ rows, loading, handleDeleteBranch }) => {
	const { requestConfirm, confirmationDialog } = useConfirmationDialog();

	const columns = useMemo<GridColDef[]>(() => [
		{
			field: 'logo',
			headerName: 'Logo',
			width: 120,
			align: 'center',
			renderCell: (params: GridRenderCellParams) => {
				const imageDataUrl = getBase64Image(params.row.logoB64);
				const boxStyle = {
					width: '50px',
					height: '50px',
					backgroundImage: `url(${imageDataUrl})`,
					backgroundSize: 'cover',
					backgroundRepeat: 'no-repeat',
					backgroundPosition: 'center',
					borderRadius: '50%',
				};

				return (
					<Box style={boxStyle} />
				);
			},
		},
		{ field: 'code', headerName: 'Código', flex: 1 },
		{ field: 'name', headerName: 'Filial', flex: 1.5 },
		{
			field: 'cnpj',
			headerName: 'CNPJ',
			flex: 1,
			valueFormatter: (params: GridValueFormatterParams) => applyCnpjMask(params.value as string),
		},
		{
			field: 'createdAt',
			headerName: 'Adicionado em',
			flex: 1,
			valueFormatter: (params: CustomValueFormatterParams) => {
				const date = new Date(params.value);
				return date.toLocaleString('pt-BR');
			},
		},
		{
			field: 'actions',
			headerName: 'Ações',
			width: 120,
			renderCell: (params: GridRenderCellParams) => (
				<>
					<Link to={`edit/${params.id}/${params.row.companyId}`}>
						<GridActionsCellItem
							icon={<EditIcon />}
							label="Editar"
							color="primary"
						/>
					</Link>
					<GridActionsCellItem
						icon={<DeleteIcon />}
						label="Excluir"
						color="error"
						onClick={() => requestConfirm({
							description: 'Tem certeza que deseja excluir a filial selecionada?',
							callback: () => handleDeleteBranch(params.id.toString(), params.row.companyId),
						})}
					/>
				</>
			),
		},
	], [handleDeleteBranch, requestConfirm]);

	return (
		<>
			<Box component={Paper} sx={{ width: '100%' }}>
				<DataGrid
					autoHeight
					rows={rows}
					pagination
					columns={columns}
					pageSize={10}
					rowsPerPageOptions={[10]}
					loading={loading}
					disableSelectionOnClick
				/>
			</Box>
			{confirmationDialog}
		</>
	);
};

BranchTable.defaultProps = {
	loading: false,
};

export default BranchTable;
