/* eslint-disable max-len */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react/require-default-props */
import React, { useCallback } from 'react';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { LoadingButton } from '@mui/lab';
import { Form, Formik } from 'formik';

import { Grid } from '@mui/material';
import {
	ILocationInfo,
	ILocationType,
	OperationType,
	validationSchemaLocation,
} from '../../../containers/Location/LocationAssets';
import Input from '../../Common/Form/Input';
import Autocomplete from '../../Common/Form/Autocomplete';
import { formatBarCode } from '../../../helpers/masks';

interface LocationModalProps {
  open: boolean;
  loading: boolean;
  initialValues: ILocationInfo;
  locationTypes: Array<ILocationType>;
  operationType: OperationType;
  onClose: (open: boolean) => void;
  onSubmit: (locationData: ILocationInfo) => void;
}

const LocationModal = ({
	open,
	initialValues,
	loading,
	locationTypes,
	operationType,
	onSubmit,
	onClose,
}: LocationModalProps): JSX.Element => {
	const buildValueBarCode = useCallback(
		(locationTypeId: string | null): string => {
			let barCodeValue = '';

			const locationTypeValue = locationTypes.find(
				(locationType) => locationType.id === locationTypeId,
			);

			if (locationTypeValue) {
				barCodeValue = locationTypeValue.prefix;
			}

			return barCodeValue;
		},
		[locationTypes],
	);

	return (
		<Dialog open={open} onClose={onClose} fullWidth>
			<Formik
				onSubmit={onSubmit}
				initialValues={initialValues}
				validationSchema={validationSchemaLocation}
				validateOnChange={false}
				validateOnBlur={false}
			>
				{({ values, setFieldValue }) => (
					<Form noValidate>
						<DialogTitle>
							{`${
								operationType === OperationType.NEW ? 'Criar' : 'Editar'
							} Localização`}
						</DialogTitle>
						<DialogContent dividers>
							<Grid container spacing={2}>
								<Grid item xs={12} md={12} sm={12}>
									<Autocomplete
										label="Tipo de Localização"
										valueLabel="level"
										valueKey="id"
										labelKey="name"
										disabled={operationType === OperationType.EDITING}
										name="locationTypeId"
										options={locationTypes || []}
										fullWidth
										isOptionEqualToValue={(option: string, value: string) => option === value}
										onChange={(event, newValue) => {
											setFieldValue('locationTypeId', newValue || '');
											const barCodeInitial = buildValueBarCode(newValue);
											setFieldValue('barCodeInitial', barCodeInitial);
										}}
										required
									/>
								</Grid>
								<Grid item xs={12} sm={12} md={12}>
									<Input.InputField
										label="Nome da Localização"
										name="name"
										fullWidth
										required
									/>
								</Grid>
								{operationType === OperationType.NEW ? (
									<>
										<Grid item xs={12} sm={12} md={6}>
											<Typography variant="subtitle1" color="#00000099">
												Código de Barras
											</Typography>
											<Typography variant="subtitle1">
												{formatBarCode(values.barCodeInitial || '') || '-'}
											</Typography>
										</Grid>
										<Grid item xs={12} sm={12} md={6}>
											<Input.InputField
												label="Composição do Código de Barras"
												name="barCodeNumber"
												fullWidth
												required
											/>
										</Grid>
									</>
								) : (
									<Grid item xs={12} sm={12} md={12}>
										<TextField
											label="Código de Barras"
											name="barCode"
											disabled
											value={formatBarCode(values.barCode)}
											fullWidth
											required
										/>
									</Grid>
								)}
							</Grid>
						</DialogContent>
						<DialogActions sx={{ gap: 1 }}>
							<Button onClick={() => onClose(false)}>Cancelar</Button>
							<LoadingButton
								type="submit"
								variant="contained"
								loading={loading}
							>
								Salvar
							</LoadingButton>
						</DialogActions>
					</Form>
				)}
			</Formik>
		</Dialog>
	);
};

export default LocationModal;
