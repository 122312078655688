import * as Yup from 'yup';
import { validationMessage } from '../../helpers/yupMessages';

export interface ICompany {
	id: string;
	code: string;
	name: string;
	createdAt: string;
	updatedAt?: string;
}

export interface ICompanyResponse {
	data: ICompany[];
	count: number;
}

export interface EditCompany {
	name: string;
	code: string;
}

export const initialValues = {
	name: '',
	code: '',
};

export const validationSchema = Yup.object().shape({
	name: Yup.string().required(validationMessage.required),
	code: Yup.string()
		.min(2, validationMessage.string.min(2))
		.required(validationMessage.required),
});
