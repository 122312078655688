import React, { useMemo } from 'react';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Link from '@mui/material/Link';
import HomeIcon from '@mui/icons-material/Home';
import { useLocation, Link as RouterLink } from 'react-router-dom';

const routes = [
	{ name: 'Armazenagem', path: '/app/storage' },
	{ name: 'Conferência', path: '/app/storage-check' },
	{ name: 'Expedição', path: '/app/expedition' },
	{ name: 'Transferência', path: '/app/transfer' },
	{ name: 'Inventário', path: '/app/inventory' },
];

const BreadcrumbMobile = (): JSX.Element => {
	const location = useLocation();

	const fullPath = useMemo(() => {
		const pathSegments = location.pathname.split('/').filter(Boolean);

		if (location.pathname === '/app') {
			return [];
		}

		return pathSegments.map((segment, index) => {
			const reconstructPath = `/${pathSegments.slice(0, index + 1).join('/')}`;
			const matchingRoute = routes.find((route) => route.path === reconstructPath);
			if (segment === 'edit' && index === pathSegments.length - 1) {
				return { name: 'Adicionar', path: reconstructPath };
			}
			if ((index === pathSegments.length - 1 && segment !== 'edit' && !matchingRoute) || (pathSegments.includes('edit') && index === pathSegments.length - 1)) {
				return { name: 'Editar', path: reconstructPath };
			}
			return matchingRoute
				? { name: matchingRoute.name, path: reconstructPath }
				: null;
		}).filter(Boolean);
	}, [location.pathname]);

	return (
		<Breadcrumbs aria-label="breadcrumb">
			{location.pathname !== '/app' && (
				<Link underline="hover" color="text.primary" component={RouterLink} to="/app">
					<HomeIcon color="primary" />
				</Link>
			)}
			{fullPath.map((route, index) => {
				if (route) {
					return index === fullPath.length - 1
						? <span key={route.path} aria-current="page">{route.name}</span>
						: (
							<Link
								key={route.path}
								underline="hover"
								color="text.primary"
								component={RouterLink}
								to={route.path}
							>
								{route.name}
							</Link>
						);
				}
				return null;
			})}
		</Breadcrumbs>
	);
};

export default BreadcrumbMobile;
