import React, { useEffect, useMemo } from 'react';
import { Formik, Form } from 'formik';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import LoadingButton from '@mui/lab/LoadingButton';
import Input from '../Common/Form/Input';
import {
	EditBranch,
	IBranch,
	initialValues,
	validationSchema,
} from '../../containers/Branch/BranchAssets';
import { cnpjMask, newPhoneMask, phoneMask } from '../../helpers/masks';
import Select from '../Common/Form/Select';
import FileUpload from '../Common/Form/DropzoneUpload';

interface BranchFormProps {
	branch?: IBranch;
	companies: Array<{ id: string; name: string }>;
	onSubmit: (values: EditBranch) => void;
	loading: boolean;
}

const BranchForm = ({
	branch,
	companies,
	onSubmit,
	loading,
}: BranchFormProps): JSX.Element => {
	const initialValuesLoad = useMemo(() => (branch ? {
		companyId: branch.companyId,
		name: branch.name,
		code: branch.code,
		cnpj: branch.cnpj,
		address: branch.address,
		email: branch.email,
		phone: branch.phone,
		logo: null,
	} : initialValues), [branch]);

	return (
		<Formik
			initialValues={initialValuesLoad}
			validationSchema={validationSchema}
			onSubmit={onSubmit}
			validateOnChange={false}
			validateOnBlur={false}
		>
			<Form noValidate>
				<Grid container spacing={2}>
					<Grid item xs={3}>
						<Select
							name="companyId"
							label="Empresa"
							options={companies.map((company) => ({ value: company.id, label: company.name }))}
							labelKey="label"
							valueKey="value"
							required
						/>
					</Grid>
					<Grid item xs={3}>
						<Input.InputField
							id="code"
							name="code"
							label="Código"
							required
							fullWidth
						/>
					</Grid>
					<Grid item xs={6}>
						<Input.InputField
							id="name"
							name="name"
							label="Nome"
							required
							fullWidth
						/>
					</Grid>
					<Grid item xs={3}>
						<Input.InputMaskField
							id="cnpj"
							name="cnpj"
							label="CNPJ"
							mask={[{ mask: cnpjMask, maxLength: 14 }]}
							required
						/>
					</Grid>
					<Grid item xs={9}>
						<Input.InputField
							id="address"
							name="address"
							label="Endereço"
							required
							fullWidth
						/>
					</Grid>
					<Grid item xs={6}>
						<Input.InputField
							id="email"
							name="email"
							label="E-mail"
							required
							fullWidth
						/>
					</Grid>
					<Grid item xs={6}>
						<Input.InputMaskField
							id="phone"
							name="phone"
							label="Telefone"
							mask={[
								{ mask: newPhoneMask, maxLength: 10 },
								{ mask: phoneMask, maxLength: 11 },
							]}
							required
						/>
					</Grid>
					<Grid item xs={12}>
						<FileUpload
							name="logo"
							currentFile={branch?.logoB64}
						/>
					</Grid>
				</Grid>
				<Box marginTop={2}>
					<LoadingButton
						loading={loading}
						type="submit"
						variant="contained"
						color="primary"
					>
						Salvar
					</LoadingButton>
				</Box>
			</Form>
		</Formik>
	);
};

BranchForm.defaultProps = {
	branch: undefined,
};

export default BranchForm;
