import React from 'react';
import {
	Paper,
	Stack,
} from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';
import { Formik, Form } from 'formik';
import {
	addUserValidationSchema, editUserValidationSchema, initialValuesAddUser, AddUser, EditUser, User,
} from '../../containers/User/UserAssets';
import { AddUserForm } from './Forms/AddUserForm';
import { EditUserForm } from './Forms/EditUserForm';

interface UserFormProps {
	loading: boolean;
	user?: User;
	handleEditUser: (values: { id?: string; data: AddUser | EditUser }) => void;
}

const UserForm = ({ loading, user, handleEditUser }: UserFormProps): JSX.Element => {
	const initialValues = user ? {
		name: user.name,
		email: user.email,
		phone: user.phone,
		cpf: user.cpf,
		registry: user.registry,
		registerHour: user.registerHour,
		canWorkHoliday: user.canWorkHoliday,
		daysBackAppointment: user.daysBackAppointment,
	} : initialValuesAddUser;

	return (
		<Formik
			initialValues={initialValues}
			validationSchema={user ? editUserValidationSchema : addUserValidationSchema}
			onSubmit={(
				values: AddUser | EditUser,
			) => handleEditUser({ id: user?.id, data: values })}
			validateOnChange={false}
			validateOnBlur={false}
		>
			<Form noValidate>
				<Paper sx={{ mt: 3, p: 3 }} elevation={3}>
					<Stack spacing="4rem">
						{
							user
								? <EditUserForm />
								: <AddUserForm />
						}
						<LoadingButton
							loading={loading}
							variant="contained"
							color="primary"
							type="submit"
							sx={{ height: 'fit-content', width: 'fit-content' }}
						>
							Salvar
						</LoadingButton>
					</Stack>
				</Paper>
			</Form>
		</Formik>
	);
};

UserForm.defaultProps = {
	user: undefined,
};

export default UserForm;
