import React from 'react';
import {
	Button,
	Card,
	CardActions,
	CardContent,
	Typography,
} from '@mui/material';
import Input from '../../Common/Form/Input';

interface InventoryStartProps {
	handleConfirmLocation: () => void;
	secondConference: boolean;
}

const InventoryLocation = ({
	handleConfirmLocation,
	secondConference,
}: InventoryStartProps):JSX.Element => (
	<Card>
		<CardContent>
			<Typography textAlign="center" variant="h6" gutterBottom>
				Localização
			</Typography>
			<Typography textAlign="center" fontWeight="bold" gutterBottom>
				30009 - MATA FORMIGA
			</Typography>
			<Typography
				textAlign="center"
				variant="subtitle1"
				gutterBottom
				sx={{ marginBottom: 2 }}
			>
				Leia o código de barras da localização
			</Typography>

			<Input.InputField
				id="productBarCode"
				name="productBarCode"
				label="Código de Barras da Localização"
				format={(value) => value.toUpperCase()}
				autoComplete="off"
				fullWidth
				required
			/>
		</CardContent>
		<CardActions>
			<Button
				variant="contained"
				size="large"
				fullWidth
				onClick={handleConfirmLocation}
			>
				Confirmar
			</Button>
		</CardActions>
	</Card>
);

export default InventoryLocation;
