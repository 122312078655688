import React from 'react';
import {
	Theme, SxProps,
} from '@mui/material/styles';
import Box from '@mui/material/Box';
import { PageHeader } from '../../Common/PageHeader/PageHeader';
import usePermission from '../../../hooks/usePermission';

const contentStyle: SxProps<Theme> = {
	width: '100%',
	display: 'flex',
	flexDirection: 'column',
	height: '100vh',
};

const BusinessIntelligence = (): JSX.Element => {
	const saleOrderPermission = usePermission('SALE_ORDER');

	let iframeSrc = 'https://lookerstudio.google.com/embed/reporting/cb9b8788-e538-46dc-bcca-749e49a533dd/page/p_s8co0s7rdd';
	if (saleOrderPermission?.isAdmin) {
		iframeSrc = 'https://lookerstudio.google.com/embed/reporting/f2bb6c6f-88d1-477d-8aa3-25e374c3607e/page/FpP5D';
	}

	return (
		<Box sx={contentStyle}>
			<PageHeader title="Business Intelligence" />
			<iframe
				title="Business Intelligence"
				src={iframeSrc}
				width="100%"
				height="100%"
				allowFullScreen
				style={{ border: 'none' }}
				sandbox="allow-storage-access-by-user-activation allow-scripts allow-same-origin allow-popups allow-popups-to-escape-sandbox"
			/>
		</Box>
	);
};

export default BusinessIntelligence;
