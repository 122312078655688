import React, {
	useCallback, useEffect, useMemo, useState,
} from 'react';
import {
	Paper,
	Box,
	Button,
	Step,
	StepLabel,
	Stepper,
} from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';
import {
	Form,
	useFormik,
	FormikContext,
} from 'formik';
import {
	IBusinessPartner,
	ISalesman,
	initialValues,
	validationSchema,
} from '../../containers/BusinessPartner/BusinessPartnerAssets';
import { ITypeContact } from '../../services/typeContact';
import { ICountry } from '../../services/country';
import ContactForm from './Forms/ContactForm';
import ProductBPForm from './Forms/ProductBPForm';
import BPForm from './Forms/BPForm';
import { Cep } from '../../interfaces/cep';
import { IRegionState } from '../../services/state';
import { ICity } from '../../services/city';
import UnitBPForm from './Forms/UnitBPForm';
import { BusinessPartnerType } from '../../enums/BusinessPartnerType';
import { IProductWithoutDetails } from '../../containers/Product/ProductAssets';

interface BusinessPartnerFormProps {
	loading: boolean;
	businessPartner?: IBusinessPartner;
	salesmans: ISalesman[];
	cep?: Cep;
	unitCep?: { cep: Cep; unitIndex: number };
	products: IProductWithoutDetails[];
	typeContacts: ITypeContact[];
	countries: ICountry[];
	states: IRegionState[];
	cities: ICity[];
	users: Array<{ id: string; name: string; login: string }>;
	getCountries(searchQuery?: string): void;
	getCities(searchQuery?: string): void;
	handleCepBlur(cep: string, unitIndex?: number): void;
	handleSubmit: (values: IBusinessPartner) => void;
}

const BusinessPartnerForm = ({
	loading,
	businessPartner,
	salesmans,
	cep,
	unitCep,
	products,
	typeContacts,
	countries,
	states,
	cities,
	users,
	getCountries,
	getCities,
	handleCepBlur,
	handleSubmit,
}: BusinessPartnerFormProps): JSX.Element => {
	const [lastSearchedCity, setLastSearchedCity] = useState('');
	const [activeStep, setActiveStep] = useState(0);

	const formik = useFormik<IBusinessPartner>({
		initialValues: businessPartner ? {
			...businessPartner,
		} : {
			...initialValues,
		},
		validationSchema,
		validateOnMount: true,
		onSubmit: (values) => {
			handleSubmit(values as IBusinessPartner);
		},
	});

	useEffect(() => {
		if (businessPartner) {
			getCities(businessPartner.city);
			setLastSearchedCity(businessPartner.city);

			businessPartner.units.forEach((unit) => {
				getCities(unit.city);
				setLastSearchedCity(unit.city);
			});
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [businessPartner]);

	useEffect(() => {
		if (cep && !cep.erro && unitCep === undefined) {
			formik.setFieldValue('address', cep.logradouro);
			formik.setFieldValue('district', cep.bairro);

			const stateObject = states.find((state) => state.abbrev === cep.uf);
			if (stateObject) {
				formik.setFieldValue('state', stateObject.name);
				if (cep.localidade !== lastSearchedCity) {
					getCities(cep.localidade);
					setLastSearchedCity(cep.localidade);
				}
				const cityObject = cities.find(
					(city) => city.name === cep.localidade && city.stateId === stateObject.id,
				);
				if (cityObject) {
					formik.setFieldValue('city', cityObject.name);
				}
			}
		} else if (unitCep && !unitCep.cep.erro && unitCep.unitIndex !== undefined) {
			const { unitIndex, cep: unitCepData } = unitCep;
			formik.setFieldValue(`units[${unitIndex}].address`, unitCepData.logradouro);
			formik.setFieldValue(`units[${unitIndex}].district`, unitCepData.bairro);
			formik.setFieldValue(`units[${unitIndex}].city`, unitCepData.localidade);

			const stateObject = states.find((state) => state.abbrev === unitCepData.uf);
			if (stateObject) {
				formik.setFieldValue(`units[${unitIndex}].state`, stateObject.name);
				if (unitCepData.localidade !== lastSearchedCity) {
					getCities(unitCepData.localidade);
					setLastSearchedCity(unitCepData.localidade);
				}
				const cityObject = cities.find(
					(city) => city.name === unitCepData.localidade
						&& city.stateId === stateObject.id,
				);
				if (cityObject) {
					formik.setFieldValue(`units[${unitIndex}].city`, cityObject.name);
				}
			}
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [cep, unitCep, cities, states, lastSearchedCity]);

	const steps = useMemo(() => {
		switch (formik.values.type) {
			case BusinessPartnerType.SALESMAN:
				return ['Matriz', 'Contatos', 'Produtos'];
			case BusinessPartnerType.DELIVERY:
				return ['Matriz', 'Unidades', 'Contatos'];
			default:
				return ['Matriz', 'Unidades', 'Contatos', 'Produtos'];
		}
	}, [formik.values.type]);

	const handleNext = useCallback(() => {
		if (activeStep === steps.length - 1) {
			formik.submitForm();
		} else {
			setActiveStep((prevActiveStep) => prevActiveStep + 1);
		}
	}, [activeStep, formik, steps.length]);

	const handleBack = useCallback(() => {
		setActiveStep((prevActiveStep) => prevActiveStep - 1);
	}, []);

	const addUnit = useCallback(() => {
		const newUnits = [
			...formik.values.units,
			{
				...initialValues.units?.[0],
				businessPartnerId: businessPartner?.id,
				type: formik.values.type,
				unitIndex: formik.values.units.length,
				tempId: `temp-${formik.values.units.length}`,
			},
		];

		formik.setFieldValue('units', newUnits);
	}, [formik, businessPartner]);

	const removeUnit = useCallback((index: number) => {
		const updatedUnits = formik.values.units.filter((_, i) => i !== index);
		formik.setFieldValue('units', updatedUnits);
	}, [formik]);

	const businessPartnerFormMemo = useMemo(() => (
		<BPForm
			salesmans={salesmans}
			countries={countries}
			states={states}
			cities={cities}
			users={users}
			getCountries={getCountries}
			getCities={getCities}
			handleCepBlur={handleCepBlur}
		/>
	), [cities, countries, getCities, getCountries, handleCepBlur, salesmans, states, users]);

	const unitFormMemo = useMemo(() => (
		<>
			{formik.values.units.map((unit, index) => (
				<UnitBPForm
					key={unit.address || unit.unitIndex}
					index={index}
					countries={countries}
					states={states}
					cities={cities}
					getCountries={getCountries}
					getCities={getCities}
					handleCepBlur={handleCepBlur}
					removeUnit={removeUnit}
				/>
			))}
			<Button variant="contained" color="primary" onClick={addUnit}>
				Adicionar Unidade
			</Button>
		</>
	), [
		formik.values.units,
		countries,
		states,
		cities,
		getCountries,
		getCities,
		handleCepBlur,
		addUnit,
		removeUnit,
	]);

	const contactFormMemo = useMemo(() => (
		<ContactForm typeContacts={typeContacts} />
	), [typeContacts]);

	const productBPFormMemo = useMemo(() => (
		<ProductBPForm products={products} />
	), [products]);

	const forms = useMemo(() => {
		const baseForms = [businessPartnerFormMemo, contactFormMemo, productBPFormMemo];
		if (formik.values.type !== BusinessPartnerType.SALESMAN) {
			const extendedForms = [
				businessPartnerFormMemo,
				unitFormMemo,
				contactFormMemo,
				productBPFormMemo,
			];
			return extendedForms;
		}
		return baseForms;
	}, [
		businessPartnerFormMemo, contactFormMemo, productBPFormMemo, unitFormMemo, formik.values.type]);

	return (
		<Box className="content">
			<Paper sx={{ p: 2, mt: 4 }} elevation={3}>
				<Stepper activeStep={activeStep} sx={{ mb: 4 }}>
					{steps.map((label) => (
						<Step key={label}>
							<StepLabel>{label}</StepLabel>
						</Step>
					))}
				</Stepper>
				<FormikContext.Provider value={formik}>
					<Form>
						{forms[activeStep]}
						<Box sx={{ display: 'flex', justifyContent: 'space-between', mt: 2 }}>
							<Button disabled={activeStep === 0} onClick={handleBack}>
								Voltar
							</Button>
							{activeStep === steps.length - 1 ? (
								<LoadingButton
									loading={loading}
									variant="contained"
									color="primary"
									disabled={!formik.isValid}
									onClick={handleNext}
								>
									Concluir
								</LoadingButton>
							) : (
								<LoadingButton
									loading={loading}
									variant="text"
									color="primary"
									onClick={handleNext}
									disabled={!formik.isValid}
								>
									Avançar
								</LoadingButton>
							)}
						</Box>
					</Form>
				</FormikContext.Provider>
			</Paper>
		</Box>
	);
};

BusinessPartnerForm.defaultProps = {
	cep: undefined,
	unitCep: undefined,
	businessPartner: undefined,
};

export default BusinessPartnerForm;
