import Yup from '../../helpers/yupMethods';
import { validationMessage } from '../../helpers/yupMessages';

export interface User {
  id: string;
  login: string;
  email: string;
  password?: string;
  name: string;
  phone: string;
  createdAt: string;
  changePasswordLogin: boolean;
  active: boolean;
  photo?: string;
  updatedAt?: string;
  cpf?: string;
  registry?: string;
  registerHour?: boolean;
  canWorkHoliday?: boolean;
  daysBackAppointment?: string;
}

export interface AddUser {
  name: string;
  email: string;
  phone: string;
  login: string;
  changePasswordLogin: boolean;
  cpf?: string;
  registry?: string;
  registerHour?: boolean;
  canWorkHoliday?: boolean;
  daysBackAppointment?: string;
}

export interface EditUser {
  name: string;
  email: string;
  phone: string;
  cpf?: string;
  registry?: string;
  registerHour?: boolean;
  canWorkHoliday?: boolean;
  daysBackAppointment?: string;
}

export interface ProjectTaskUserStatus {
  status: boolean;
  projectTaskId: string;
  hourProjectId: string;
}

export interface UserWithProjectTaskStatus {
  id: string;
  name: string;
  login: string;
  projectTaskUsers: ProjectTaskUserStatus[];
}

export interface GetProjectUsers {
  data: UserWithProjectTaskStatus[];
  count: number;
}

export interface CurrentUserProps {
  id: string;
  name: string;
}

export const initialValuesAddUser = {
	name: '',
	email: '',
	phone: '',
	login: '',
	password: '',
	changePasswordLogin: true,
	cpf: '',
	registry: '',
	registerHour: false,
	canWorkHoliday: false,
	daysBackAppointment: '',
};

export const addUserValidationSchema = Yup.object().shape({
	name: Yup.string().required(validationMessage.required),
	email: Yup.string()
		.email(validationMessage.string.email)
		.required(validationMessage.required),
	phone: Yup.string()
		.min(10, validationMessage.string.min(15))
		.max(16, validationMessage.string.max(16))
		.required(validationMessage.required),
	login: Yup.string().required(validationMessage.required),
	password: Yup.string().required(validationMessage.required),
	cpf: Yup.string().cpf().nullable(true),
	daysBackAppointment: Yup.string()
		.max(3, validationMessage.string.max(3))
		.matches(/^[0-9]*$/, validationMessage.number.typeError)
		.nullable(true),
});

export const editUserValidationSchema = Yup.object().shape({
	name: Yup.string().required(validationMessage.required),
	email: Yup.string()
		.email(validationMessage.string.email)
		.required(validationMessage.required),
	phone: Yup.string()
		.min(10, validationMessage.string.min(15))
		.max(16, validationMessage.string.max(16))
		.required(validationMessage.required),
	cpf: Yup.string().cpf().nullable(true),
	daysBackAppointment: Yup.string()
		.max(3, validationMessage.string.max(3))
		.matches(/^[0-9]*$/, validationMessage.number.typeError)
		.nullable(true),
});
