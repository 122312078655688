import { SalePriceFormationStatusTypeLabels } from '../../constants/salePriceFormationStatusTypeLabels';
import { SalePriceFormationStatus } from '../../enums/SalePriceFormationStatus';
import { SalePriceFormationQueryParams } from '../../interfaces/SalePriceFormationQueryParams';

export interface ISalePriceFormation {
  id?: string;
  code: number;
  revision: string;
  productId: string;
  totalDirectCost: number;
  status: SalePriceFormationStatus;
  costBaseMonth: string;
  priceListStateSalePriceFormation: IPriceListStateSalePriceFormation;
  salePrice?: number;
  totalIndirectCost?: number;
  baseCommission?: number;
  averageTermId?: string;
  averageTerm?: string;
  userResponsibleId?: string;
  priceList?: string;
  priceListStateSalePriceFormationId?: string;
  validity?: Date;
  informed?: number;
  productCode?: string;
  productDescription?: string;
  formula?: string;
  actionJustificative?: string;
  indirectCostSalePriceFormations?: IIndirectCostSalePriceFormation[];
  averagePaymentTermSalePriceFormations?: IAveragePaymentTermSalePriceFormation[];
  rangeCommissionSalePriceFormations?: IRangeCommissionSalePriceFormation[];
  items: ISalePriceFormationItem[];
}

export interface ISalePriceFormationItem {
  id?: string;
  code: string;
  description: string;
  quantity: number;
  averageCost: number;
  lastPrice: number;
  informed?: number;
  composesCost: boolean;
}

export interface ISalePriceFormations {
  data: ISalePriceFormation[];
  count: number;
}

export interface IIndirectCost {
  id: string;
  code: string;
  description: string;
  interestRate: number;
}

export interface IAveragePaymentTerm {
  id: string;
  code: string;
  description: string;
  initialTerm: number;
  finalTerm: number;
  interestRate: number;
  validity: Date;
}

export interface IRangeCommission {
  id: string;
  range: number;
  description: string;
  commissionRate: number;
}

export interface ICreateIndirectCost {
  description: string;
  interestRate: number;
}

export interface IIndirectCostSalePriceFormation {
  indirectCostId: string;
  salePriceFormationId: string;
  interestRate: number;
  code?: string;
  description?: string;
}

export interface ICreateAveragePaymentTerm {
  description: string;
  initialTerm: number;
  finalTerm: number;
  interestRate: number;
}

export interface IAveragePaymentTermSalePriceFormation {
  id?: string;
  averagePaymentTermId: string;
  salePriceFormationId: string;
  interestRate: number;
  code?: string;
  description?: string;
  withDiscount?: boolean;
}

export interface ICreateRangeCommission {
  commissionRate: number;
  range: number;
}

export interface IRangeCommissionSalePriceFormation {
  rangeCommissionId: string;
  salePriceFormationId: string;
  commissionRate: number;
  range?: number;
  description?: string;
}

export interface IPriceListStateSalePriceFormation {
  id: string;
  priceListStateId: string;
  salePriceFormationId: string;
  discount: number;
  priceListState: {
    priceList: string;
  };
}

export interface IExtendedSalePriceFormation extends ISalePriceFormation {
  currentCostId?: string;
  currentCostRate?: number;
  currentCostCode?: string;
  currentCostDescription?: string;
  newCostDescription?: string;
  newCostRate?: string;
  currentAveragePaymentTermId?: string;
  currentAveragePaymentTermRate?: number;
  currentAveragePaymentTermCode?: string;
  currentAveragePaymentTermDescription?: string;
  newAveragePaymentTermDescription?: string;
  newAveragePaymentTermRate?: string;
  newAveragePaymentTerm?: string;
  currentRangeCommissionId?: string;
  currentRangeCommissionRate?: number;
  currentRange?: string;
  newRangeCommissionRate?: string;
  newRange?: string;
  indexValue?: number;
  indexValues?: { [key: string]: string };
  discountIndex?: number;
}

export type IUpdateSalePriceFormationDto = Partial<ISalePriceFormation>;

export interface ICostBaseMonth {
  month: string;
  year: number;
}

export const initialValues: IExtendedSalePriceFormation = {
	code: 0,
	revision: '000',
	productId: '',
	totalDirectCost: 0,
	status: 0,
	salePrice: 0,
	totalIndirectCost: 0,
	costBaseMonth: '',
	baseCommission: 0,
	averageTermId: '',
	indexValue: 0,
	userResponsibleId: '',
	formula: '',
	validity: new Date(),
	informed: 0,
	items: [
		{
			quantity: 0,
			averageCost: 0,
			lastPrice: 0,
			code: '',
			description: '',
			composesCost: true,
		},
	],
	averagePaymentTermSalePriceFormations: [
		{
			averagePaymentTermId: '',
			salePriceFormationId: '',
			interestRate: 0,
			description: '',
			withDiscount: false,
		},
	],
	rangeCommissionSalePriceFormations: [
		{
			rangeCommissionId: '',
			salePriceFormationId: '',
			commissionRate: 0,
			range: 0,
			description: '',
		},
	],
	priceListStateSalePriceFormation: {
		id: '',
		priceListStateId: '',
		salePriceFormationId: '',
		discount: 0,
		priceListState: {
			priceList: '',
		},
	},
};

export const initialValuesLoad: SalePriceFormationQueryParams = {
	skip: 0,
	startDate: '',
	endDate: '',
};

export const statusOptions = Object.entries(
	SalePriceFormationStatusTypeLabels,
).map(([value, label]) => ({
	id: value,
	value,
	label,
}));
