import React, {
	Dispatch, Reducer, useMemo, useReducer,
} from 'react';
import { NavigateFunction, useNavigate } from 'react-router-dom';
import {
	OptionsObject, SnackbarKey, SnackbarMessage, useSnackbar,
} from 'notistack';
import { AxiosError } from 'axios';
import LocationEdit from '../../components/Location/LocationEdit';
import {
	deleteLocation, getLocationsMain, saveLocation, updateLocation,
} from '../../services/location';
import {
	ILocation, ILocationRequest, ILocationType,
} from './LocationAssets';
import { getLocationTypes } from '../../services/locationType';

enum ActionType {
	LOADING,
	LOCATION_TYPES,
	LOCATIONS_MAIN
}

interface IState {
	loading: boolean;
	count: number;
	locationTypes: ILocationType[];
	locationsMain: ILocation[];
}

type TAction =
	| { type: ActionType.LOADING; payload: { loading: boolean } }
	| { type: ActionType.LOCATION_TYPES; payload: { locationTypes: ILocationType[]; count: number } }
	| { type: ActionType.LOCATIONS_MAIN; payload: { locationsMain: ILocation[] } };

interface ILocationActions {
    setLoading(loading: boolean): void;
	getLocationTypes():void;
	getLocationsMain: () => void;
	saveLocation: (data: ILocationRequest) => void;
	updateLocation: (id: string, data: ILocationRequest) => void;
	deleteLocation: (id: string) => void;
}

const initialState: IState = {
	loading: false,
	locationTypes: [],
	locationsMain: [],
	count: 0,
};

const reducer: Reducer<IState, TAction> = (state, action) => {
	switch (action.type) {
		case ActionType.LOADING:
			return { ...state, loading: action.payload.loading };
		case ActionType.LOCATION_TYPES:
			return { ...state, count: action.payload.count, locationTypes: action.payload.locationTypes };
		case ActionType.LOCATIONS_MAIN:
			return { ...state, locationsMain: action.payload.locationsMain };
		default:
			throw new Error();
	}
};

const LocationActions = (
	dispatch: Dispatch<TAction>,
	enqueueSnackbar: (message: SnackbarMessage, options?: OptionsObject | undefined) => SnackbarKey,
	navigate: NavigateFunction,
): ILocationActions => {
	const actions = {
		setLoading(loading: boolean) {
			dispatch({ type: ActionType.LOADING, payload: { loading } });
		},
		getLocationTypes() {
			actions.setLoading(true);
			getLocationTypes()
				.then((response) => {
					dispatch({
						type: ActionType.LOCATION_TYPES,
						payload: {
							locationTypes: response.data.data,
							count: response.data.count,
						},
					});
				})
				.catch((error: AxiosError) => {
					enqueueSnackbar(
						error.response?.data.message || 'Algum erro ocorreu, tente novamente ou contate um administrador.',
						{
							variant: 'error',
						},
					);
				})
				.finally(() => {
					actions.setLoading(false);
				});
		},
		updateLocation(
			id: string,
			data: ILocationRequest,
		) {
			actions.setLoading(true);
			updateLocation(id, data)
				.then((response) => {
					enqueueSnackbar(
						response.data.message || 'Localização atualizada com sucesso.',
						{ variant: 'success' },
					);
					actions.getLocationsMain();
				})
				.catch((error: AxiosError) => {
					enqueueSnackbar(
						error.response?.data.message || 'Algum erro ocorreu, tente novamente ou contate um administrador.',
						{ variant: 'error' },
					);
				})
				.finally(() => {
					actions.setLoading(false);
				});
		},
		saveLocation(
			data: ILocationRequest,
		) {
			actions.setLoading(true);
			saveLocation(data)
				.then((response) => {
					enqueueSnackbar(
						response.data.message || 'Localização adicionada com sucesso.',
						{ variant: 'success' },
					);
					actions.getLocationsMain();
				})
				.catch((error: AxiosError) => {
					enqueueSnackbar(
						error.response?.data.message || 'Algum erro ocorreu, tente novamente ou contate um administrador.',
						{ variant: 'error' },
					);
				})
				.finally(() => {
					actions.setLoading(false);
				});
		},
		deleteLocation(locationId: string) {
			actions.setLoading(true);
			deleteLocation(locationId)
				.then((response) => {
					enqueueSnackbar(response?.data.message || 'Localização excluída com sucesso.', { variant: 'success' });
					actions.getLocationsMain();
				})
				.catch((error: AxiosError) => {
					enqueueSnackbar(error.response?.data.message || 'Algum erro ocorreu, tente novamente ou contate um administrador.', {
						variant: 'error',
					});
					actions.setLoading(false);
				});
		},
		getLocationsMain() {
			actions.setLoading(true);
			getLocationsMain()
				.then((response) => {
					dispatch({
						type: ActionType.LOCATIONS_MAIN,
						payload: {
							locationsMain: response.data,
						},
					});
				})
				.catch((error: AxiosError) => {
					enqueueSnackbar(
						error.response?.data.message || 'Algum erro ocorreu, tente novamente ou contate um administrador.',
						{ variant: 'error' },
					);
				})
				.finally(() => {
					actions.setLoading(false);
				});
		},
	};

	return actions;
};

// eslint-disable-next-line react/jsx-props-no-spreading
const LocationEditContainer = (): JSX.Element => {
	const [state, dispatch] = useReducer<Reducer<IState, TAction>>(reducer, initialState);
	const { enqueueSnackbar } = useSnackbar();
	const navigate = useNavigate();
	const actions = useMemo(
		() => LocationActions(dispatch, enqueueSnackbar, navigate),
		[enqueueSnackbar, navigate],
	);

	// eslint-disable-next-line react/jsx-props-no-spreading
	return <LocationEdit {...state} {...actions} />;
};

export default LocationEditContainer;
