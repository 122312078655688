/* eslint-disable max-len */
/* eslint-disable react/jsx-props-no-spreading */
import React, { Fragment, useMemo } from 'react';
import omit from 'lodash/omit';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import Badge from '@mui/material/Badge';
import { Skeleton, SxProps, Theme } from '@mui/material';
import {
	Buttons,
	PageHeaderButtonProps,
	IPageHeaderProps,
} from '../../../interfaces/PageHeaderInterface';
import { PageHeaderMenu } from './PageHeaderMenu';

const headerStyle: SxProps<Theme> = {
	display: 'flex',
	justifyContent: 'space-between',
	alignItems: 'center',
	mb: 5,
};

const buttonStyle: SxProps<Theme> = {
	width: 'fit-content',
	height: 'fit-content',
	minWidth: { xs: 'fit-content', lg: 200 },
};

const getButtons = (
	buttons: PageHeaderButtonProps[] | undefined,
): Buttons | null => {
	if (!buttons) return null;

	const buttonsToShow: PageHeaderButtonProps[] = buttons.filter(
		({ show = true }) => show,
	);

	const menuButtons: PageHeaderButtonProps[] = [...buttonsToShow];
	const primaryButtons: PageHeaderButtonProps[] = menuButtons.splice(0, 10);
	return { primaryButtons, menuButtons };
};

export const PageHeader = ({
	title,
	subtitle,
	buttons,
	loading,
}: IPageHeaderProps): JSX.Element => {
	const buttonsMemo = useMemo(() => getButtons(buttons), [buttons]);

	const getButtonProps = (
		buttonProps: PageHeaderButtonProps,
	): Omit<
    PageHeaderButtonProps,
    'text' | 'show' | 'badgeContent'
  > => omit(buttonProps, ['text', 'show', 'badgeContent']);

	const primaryButtonsMemo = useMemo(() => {
		if (loading) {
			return [0, 1, 2].map((item) => (
				<Skeleton
					key={item}
					variant="rectangular"
					sx={{ width: 200, height: '100%', borderRadius: 1 }}
				/>
			));
		}

		return buttonsMemo?.primaryButtons.map((buttonProps) => (
			<Badge
				key={buttonProps.text}
				badgeContent={buttonProps.badgeContent}
				color="secondary"
			>
				{buttonProps.isCustomComponent ? (
					buttonProps.component
				) : (
					<Button {...getButtonProps(buttonProps)} sx={buttonStyle}>
						{buttonProps.text}
					</Button>
				)}
			</Badge>
		));
	}, [buttonsMemo?.primaryButtons, loading]);

	const renderSubtitle = subtitle?.split('\n').map((line, index) => (
		// eslint-disable-next-line react/no-array-index-key
		<Typography key={index} variant="subtitle2" color="GrayText">
			{line}
		</Typography>
	));

	return (
		<Box sx={headerStyle}>
			<Box>
				<Typography variant="h4" color="primary">
					{title}
				</Typography>
				{renderSubtitle}
			</Box>
			{buttonsMemo && (
				<Stack direction="row" spacing={2}>
					{primaryButtonsMemo}
					{buttonsMemo.menuButtons.length > 0 && (
						<PageHeaderMenu options={buttonsMemo.menuButtons} />
					)}
				</Stack>
			)}
		</Box>
	);
};
