import React, {
	useCallback, useEffect, useMemo, useRef,
} from 'react';
import {
	Box,
	Typography,
	Button,
	Paper,
} from '@mui/material';
import {
	Form, FormikContext, useFormik,
} from 'formik';
import { useSnackbar } from 'notistack';
import Input from '../../Common/Form/Input';
import { ITask } from '../../../containers/Mobile/TaskAssets';
import { Loading } from '../../Common/Loading';
import { formatBarCode } from '../../../helpers/masks';

interface ExpeditionListProps {
	transferTask: ITask;
	loading: boolean;
	handleFinishTask: (task: ITask) => void;
}

const initialValues = {
	locationDestinyConfirmation: '',
};

const TransferFinish = ({
	transferTask,
	handleFinishTask,
	loading,
}: ExpeditionListProps): JSX.Element => {
	const { enqueueSnackbar } = useSnackbar();
	const inputRef = useRef<HTMLInputElement>(null);

	useEffect(() => {
		if (inputRef.current) {
			inputRef.current.focus();
		}
	}, [transferTask, loading]);

	const handleValidateLocation = useCallback((locationDestinyConfirmation: string): void => {
		if (formatBarCode(transferTask.locationDestiny.barCode) === locationDestinyConfirmation) {
			handleFinishTask(transferTask);
		} else {
			enqueueSnackbar('Localização de destino inválida.', {
				variant: 'error',
			});
		}
	}, [enqueueSnackbar, handleFinishTask, transferTask]);

	const formik = useFormik({
		initialValues,
		onSubmit: () => {
			handleValidateLocation(formik.values.locationDestinyConfirmation);
		},
	});

	const handleBlurOrEnter = useCallback((event: React.KeyboardEvent<HTMLInputElement>): void => {
		if ((event.key === 'Tab') || (event.key === 'Enter')) {
			event.preventDefault();
			handleValidateLocation(formik.values.locationDestinyConfirmation);
		}
	}, [formik.values.locationDestinyConfirmation, handleValidateLocation]);

	const formattedBarCode = useMemo(() => formatBarCode(
		transferTask.locationDestiny.barCode,
	), [transferTask.locationDestiny.barCode]);

	if (loading) {
		<Loading />;
	}

	return (
		<FormikContext.Provider value={formik}>
			<Form>
				<Box padding={1} sx={{ mt: 4 }}>
					<Typography variant="h6" align="center">
						Confirme a Localização Destino
					</Typography>

					<Typography align="center">
						{`Localização de Destino: ${formattedBarCode}`}
					</Typography>

					<Paper sx={{ boxShadow: 'none', marginTop: 6, marginBottom: 2 }}>
						<Input.InputField
							id="locationDestinyConfirmation"
							name="locationDestinyConfirmation"
							label="Digite o código da localização"
							inputRef={inputRef}
							autoComplete="off"
							InputProps={{
								inputProps: {
									min: 0,
									step: 'any',
								},
							}}
							fullWidth
							format={(value) => formatBarCode(value).toUpperCase()}
							onKeyDown={handleBlurOrEnter}
						/>
					</Paper>
					<Button
						sx={{ mt: 2, width: '100%' }}
						size="large"
						variant="contained"
						type="submit"
					>
						Confirmar
					</Button>
				</Box>
			</Form>
		</FormikContext.Provider>
	);
};

export default TransferFinish;
