import React, { useEffect, useRef, useState } from 'react';
import {
	Box,
	Typography,
	Accordion,
	AccordionSummary,
	AccordionDetails,
	Button,
	Grid,
	IconButton,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {
	Add, Remove,
} from '@mui/icons-material';
import { Form, useFormikContext } from 'formik';
import { useSnackbar } from 'notistack';
import Input from '../../Common/Form/Input';
import useConfirmationDialog from '../../../hooks/useConfirmationDialog';
import { SaveTaskType } from '../../../services/storage';
import { GroupTasks, TaskStorageFormik } from '../../../containers/Mobile/Storage/StorageAssets';
import { ITask } from '../../../containers/Mobile/TaskAssets';
import { InventoryTaskStatus } from '../../../enums/InventoryTaskStatus';
import { Loading } from '../../Common/Loading';
import { formatBarCode } from '../../../helpers/masks';

interface StorageLocationProps {
	saveTaskCount: (data: SaveTaskType) => void;
	getLocationOrigin: (barCode: string, status: InventoryTaskStatus) => void;
	tasks: ITask[];
	handleFinishStorage: () => void;
	loading: boolean;
	handleBack: () => void;
}

const StorageConfirmation = ({
	saveTaskCount,
	getLocationOrigin,
	handleFinishStorage,
	tasks,
	loading,
	handleBack,
}: StorageLocationProps): JSX.Element => {
	const [showProducts, setShowProducts] = useState<{ [key: string]: boolean }>({});
	const [expanded, setExpanded] = useState<null | string>(null);
	const locationInputRefs = useRef<{ [key: string]: HTMLInputElement | null }>({});
	const barcodeInputRefs = useRef<{ [key: string]: HTMLInputElement | null }>({});

	const { requestConfirm, confirmationDialog } = useConfirmationDialog();

	const { enqueueSnackbar } = useSnackbar();

	const { values, setFieldValue } = useFormikContext<TaskStorageFormik>();

	useEffect(() => {
		const barCode = 'A01Z01';
		getLocationOrigin(barCode, InventoryTaskStatus.OPERATING);
	}, []);

	useEffect(() => {
		if (tasks.length > 0) {
			setExpanded(tasks[0].locationDestiny.barCode);
		}
	}, [tasks]);

	useEffect(() => {
		if (expanded && locationInputRefs.current[expanded]) {
			const locationInput = locationInputRefs.current[expanded];
			if (locationInput) {
				locationInput.focus();
			}
		}

		if (expanded && showProducts[expanded]) {
			const firstProductBarcode = barcodeInputRefs.current[tasks.find((task) => task.locationDestiny.barCode === expanded)?.id ?? ''];
			if (firstProductBarcode) {
				firstProductBarcode.focus();
			}
		}
	}, [expanded, showProducts]);

	const confirmQuantity = (task: ITask): void => {
		saveTaskCount({ taskId: task.id, quantity: Number(values[`quantity-${task.id}`]) });
	};

	const markAsIncorrect = (task: ITask): void => {
		saveTaskCount({ taskId: task.id, quantity: Number(values[`quantity-${task.id}`]) });
	};

	const handleIncrement = (taskId: string): void => {
		if (!values[`quantity-${taskId}`]) {
			setFieldValue(`quantity-${taskId}`, 1);
			return;
		}
		setFieldValue(`quantity-${taskId}`, (Number(values[`quantity-${taskId}`]) ?? 0) + 1);
	};

	const handleDecrement = (taskId: string): void => {
		if (!Number(values[`quantity-${taskId}`])) {
			return;
		}
		setFieldValue(`quantity-${taskId}`, (Number(values[`quantity-${taskId}`]) ?? 0) - 1);
	};

	const handleConfirmBarcode = (barCode: string): void => {
		if (values[`barcode-${barCode}`] === barCode) {
			setShowProducts((prev) => ({
				...prev,
				[barCode]: true,
			}));
		} else {
			enqueueSnackbar('Código de barras inválido', {
				variant: 'error',
			});
		}
	};

	const groupedProducts = tasks.reduce((
		acc: Record<string, GroupTasks>,
		task: ITask,
	): Record<string, GroupTasks> => {
		if (task.locationDestiny) {
			const { barCode } = task.locationDestiny;
			if (!acc[barCode]) {
				acc[barCode] = {
					barCode,
					tasks: [],
				};
			}
			acc[barCode].tasks.push(task);
		}
		return acc;
	}, {});

	const groupedProductsArray = Object.values(groupedProducts);

	const handleBlurOrEnter = (event: React.KeyboardEvent<HTMLInputElement>, task: any): void => {
		const target = event.target as HTMLInputElement;
		if ((event.key === 'Tab') || (event.key === 'Enter')) {
			event.preventDefault();
			if (task.product.code === target.value) {
				handleIncrement(task.id);
			} else {
				enqueueSnackbar('Código de barras inválido', {
					variant: 'error',
				});
			}
			setFieldValue(`barcode-${task.id}`, '');
		}
	};

	const handleBlurOrEnterLocation = (
		event: React.KeyboardEvent<HTMLInputElement>,
		group: GroupTasks,
	): void => {
		const target = event.target as HTMLInputElement;
		if ((event.key === 'Tab') || (event.key === 'Enter')) {
			event.preventDefault();
			if (group.barCode === target.value) {
				handleConfirmBarcode(group.barCode);
			} else {
				enqueueSnackbar('Código de barras inválido', {
					variant: 'error',
				});
			}
		}
	};

	const handleExpanded = (barCode: string): void => {
		setExpanded((prevState) => (prevState === barCode ? null : barCode));
	};

	if (!loading && tasks.length === 0) {
		return (
			<Box sx={{ mt: 2 }}>
				<Typography textAlign="center">Não há produtos nesta localização.</Typography>
				<Button
					sx={{ mt: 4, width: '100%' }}
					size="large"
					variant="contained"
					onClick={handleBack}
				>
					Ir para Tela Inicial
				</Button>
			</Box>
		);
	}

	if (loading) {
		return <Loading />;
	}

	return (
		<Box>
			<Box>
				<Typography variant="h6" align="center" marginBottom={2}>
					Siga para as localizações abaixo:
				</Typography>
				{groupedProductsArray.map((group) => (
					<Accordion
						key={group.barCode}
						sx={{ marginBottom: 2, borderRadius: 1, boxShadow: 1 }}
						expanded={expanded === group.barCode}
					>
						<AccordionSummary
							expandIcon={<ExpandMoreIcon />}
							aria-controls={`panel${group.barCode}-content`}
							id={`panel${group.barCode}-header`}
							onClick={() => handleExpanded(group.barCode)}
						>
							<Box display="flex" alignItems="center" gap={1}>
								<Typography sx={{ textTransform: 'uppercase' }}>
									{`${formatBarCode(group.barCode)}`}
								</Typography>
							</Box>
						</AccordionSummary>
						<AccordionDetails>
							<Form>
								<Grid>
									{showProducts[group.barCode] ? (
										group.tasks.map((task) => (
											<Box
												key={task.id}
												sx={{
													marginBottom: 2, padding: 2, borderRadius: 1, boxShadow: 1,
												}}
											>
												<Box display="flex" alignItems="center" gap={1} mb={1}>
													<Typography>{`${task.product.code} - ${task.product.description}`}</Typography>
												</Box>
												<Typography sx={{ fontWeight: 'bold', mb: 1 }}>
													{`${task.quantity} ${task.product.measures[0].description}(s)`}
												</Typography>

												<Grid item xs={12} sm={6}>
													<Input.InputField
														label="Código de Barras"
														id={`barcode-${task.id}`}
														name={`barcode-${task.id}`}
														inputRef={
															// eslint-disable-next-line no-return-assign
															(el) => barcodeInputRefs.current[task.id] = el
														}
														sx={{ mb: 2 }}
														fullWidth
														onKeyDown={(
															event: React.KeyboardEvent<HTMLInputElement>,
														) => handleBlurOrEnter(event, task)}
													/>
												</Grid>

												<Grid item xs={12} sm={6}>
													<Box
														sx={{
															width: '100%',
															display: 'flex',
															alignItems: 'center',
															justifyContent: 'center',
														}}
													>
														<IconButton size="large" disabled={Number(values[`quantity-${task.id}`]) === 0} onClick={() => handleDecrement(task.id)}>
															<Remove />
														</IconButton>
														<Input.InputField
															id={`quantity-${task.id}`}
															name={`quantity-${task.id}`}
															autoComplete="off"
															size="small"
															sx={{ width: 80, textAlign: 'center' }}
															required
														/>
														<IconButton size="large" onClick={() => handleIncrement(task.id)}>
															<Add />
														</IconButton>
													</Box>
												</Grid>
												<Grid item xs={12} sm={6} display="flex" justifyContent="space-between" marginTop={2} gap={2}>
													{Number(values[`quantity-${task.id}`]) === Number(task.quantity) ? (
														<Button
															variant="contained"
															size="small"
															color="primary"
															sx={{ width: '100%' }}
															onClick={() => confirmQuantity(task)}
															disabled={Number(values[`quantity-${task.id}`]) !== Number(task.quantity)}
														>
															Confirmar
														</Button>
													) : (
														<Button
															variant="outlined"
															size="small"
															color="error"
															sx={{ width: '100%' }}
															onClick={() => requestConfirm({
																title: 'Confirmar Inconsistência',
																description: 'Existe uma diferença entre a quantidade informada e a esperada, deseja confirmar mesmo assim?',
																callback: () => markAsIncorrect(task),
															})}
														>
															Inconsistente
														</Button>
													)}

												</Grid>
											</Box>
										))
									) : (
										<>
											<Typography textAlign="center" gutterBottom>
												Informe a localização de destino
											</Typography>

											<Input.InputField
												id={`barcode-${group.barCode}`}
												name={`barcode-${group.barCode}`}
												label="Código de Barras da Localização"
												format={(value) => value.toUpperCase()}
												autoComplete="off"
												// eslint-disable-next-line no-return-assign
												inputRef={(el) => locationInputRefs.current[group.barCode] = el}
												onKeyDown={(
													event: React.KeyboardEvent<HTMLInputElement>,
												) => handleBlurOrEnterLocation(event, group)}
												fullWidth
												required
											/>
											<Button
												sx={{ width: '100%', mt: 2 }}
												size="large"
												variant="outlined"
												onClick={() => handleConfirmBarcode(group.barCode)}
											>
												Confirmar Código de Barras
											</Button>
										</>
									)}
								</Grid>
							</Form>
						</AccordionDetails>
					</Accordion>
				))}
				<Button sx={{ width: '100%', mt: 4 }} size="large" variant="contained" onClick={handleFinishStorage}>
					Concluir Armazenagem
				</Button>
				{confirmationDialog}
			</Box>
		</Box>

	);
};

export default StorageConfirmation;
