import React from 'react';
import { Stack } from '@mui/material';
import {
	FormTitle, PersonalData, LoginConfig,
} from './UserFormSections';

export const AddUserForm = (): JSX.Element => (
	<Stack spacing={8}>
		<FormTitle>Adicionar usuário</FormTitle>
		<PersonalData />
		<LoginConfig />
	</Stack>
);
