import { AxiosResponse } from 'axios';
import api from './api';
import {
	ISalePriceFormations,
	ISalePriceFormation,
	IUpdateSalePriceFormationDto,
	IIndirectCost,
	ICreateIndirectCost,
	IAveragePaymentTerm,
	ICreateAveragePaymentTerm,
	IAveragePaymentTermSalePriceFormation,
	IRangeCommission,
	ICreateRangeCommission,
	ISalePriceFormationItem,
} from '../containers/SalePriceFormation/SalePriceFormationAssets';
import { SalePriceFormationQueryParams } from '../interfaces/SalePriceFormationQueryParams';

export interface IMessageResponse {
  message: string;
}

interface IUpsertAveragePaymentTermResponse extends IMessageResponse {
  data: IAveragePaymentTermSalePriceFormation[];
}

interface IRemoveAveragePaymentTermResponse extends IMessageResponse {
  data: IAveragePaymentTermSalePriceFormation[];
}

interface IUpdateCostBaseMonthResponse extends IMessageResponse {
  data: ISalePriceFormationItem[];
}

interface ISalePriceFormationResponse extends IMessageResponse {
  data?: string;
}

export const getSalePriceFormations = (
	params?: SalePriceFormationQueryParams,
): Promise<AxiosResponse<ISalePriceFormations>> => api.get<ISalePriceFormations>('/sale-price-formation', { params });

export const getSalePriceFormationById = (
	id: string,
	costBaseMonth?: Date,
): Promise<AxiosResponse<ISalePriceFormation>> => api.get<ISalePriceFormation>(`/sale-price-formation/${id}`, {
	params: { costBaseMonth },
});

export const getCostBaseMonths = (): Promise<
  AxiosResponse<{ month: string; year: number }[]>
> => api.get<{ month: string; year: number }[]>(
	'/sale-price-formation/cost-base-months',
);

export const getPriceLists = (): Promise<AxiosResponse<string[]>> => api.get<string[]>('/sale-price-formation/price-lists');

export const getIndirectCosts = (): Promise<AxiosResponse<IIndirectCost[]>> => api.get<IIndirectCost[]>('/sale-price-formation/indirect-costs');

export const getAveragePaymentTerms = (): Promise<
  AxiosResponse<IAveragePaymentTerm[]>
> => api.get<IAveragePaymentTerm[]>('/sale-price-formation/average-payment-terms');

export const getRangeCommissions = (): Promise<
  AxiosResponse<IRangeCommission[]>
> => api.get<IRangeCommission[]>('/sale-price-formation/range-commissions');

export const createIndirectCost = (
	data: ICreateIndirectCost,
): Promise<AxiosResponse<IMessageResponse>> => api.post<IMessageResponse>('/sale-price-formation/indirect-cost', data);

export const createAveragePaymentTerm = (
	data: ICreateAveragePaymentTerm,
): Promise<AxiosResponse<IMessageResponse>> => api.post<IMessageResponse>(
	'/sale-price-formation/average-payment-term',
	data,
);

export const createRangeCommission = (
	data: ICreateRangeCommission,
): Promise<AxiosResponse<IMessageResponse>> => api.post<IMessageResponse>('/sale-price-formation/range-commission', data);

export const createSalePriceFormation = (
	data: ISalePriceFormation,
): Promise<AxiosResponse<ISalePriceFormationResponse>> => api.post<ISalePriceFormationResponse>('/sale-price-formation', data);

export const upsertAveragePaymentTermToFormation = (
	data: IAveragePaymentTermSalePriceFormation,
): Promise<AxiosResponse<IUpsertAveragePaymentTermResponse>> => api
	.post<IUpsertAveragePaymentTermResponse>(
		'/sale-price-formation/average-payment-term/upsert',
		data,
	);

export const updateSalePriceFormation = (
	id: string,
	data: IUpdateSalePriceFormationDto,
): Promise<AxiosResponse<ISalePriceFormationResponse>> => api
	.patch<IUpdateSalePriceFormationDto, AxiosResponse<ISalePriceFormationResponse>>(
		`/sale-price-formation/${id}`,
		data,
	);

export const updateCostBaseMonth = (
	id: string,
	costBaseMonth: Date,
): Promise<AxiosResponse<IUpdateCostBaseMonthResponse>> => api.patch<
		{ costBaseMonth: Date },
		AxiosResponse<IUpdateCostBaseMonthResponse>
	>(`/sale-price-formation/cost-base-month/${id}`, { costBaseMonth });

export const deleteSalePriceFormation = (
	id: string,
): Promise<AxiosResponse<IMessageResponse>> => api.delete<IMessageResponse>(`/sale-price-formation/${id}`);

export const removeAveragePaymentTermFromFormation = (
	id: string,
): Promise<AxiosResponse<IRemoveAveragePaymentTermResponse>> => api
	.delete<IRemoveAveragePaymentTermResponse>(
		`/sale-price-formation/average-payment-term/remove?id=${id}`,
	);
