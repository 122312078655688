import React, {
	Dispatch,
	SetStateAction,
	useCallback,
	useEffect,
	useMemo,
	useState,
} from 'react';
import {
	Accordion,
	AccordionDetails,
	AccordionSummary,
	Box,
	Stack,
	Typography,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {
	DataGrid,
	GridActionsCellItem,
	GridRenderCellParams,
} from '@mui/x-data-grid';
import { Delete, Edit } from '@mui/icons-material';
import { ToolbarAddComponent } from '../Common/Datagrid/DataGridAddToolBar';
import {
	EditProductFormProps,
	ILocation, ILocationProductTableRow,
	ILocationWithPagination,
} from '../../containers/Location/LocationAssets';
import { locationProductsColumns } from '../../constants/locationProductsColumns';
import useConfirmationDialog from '../../hooks/useConfirmationDialog';
import { formatProductsByLocationTable } from '../../helpers/formatProductsByLocationTable';
import AddProductModal, { AddProductFormProps } from './Modals/AddProductModal';
import { ProductQueryParams } from '../../interfaces/ProductQueryParams';
import { IProduct } from '../../containers/Product/ProductAssets';
import EditProductModal from './Modals/EditProductModal';
import NoDataPage from '../Common/NoDataPage';
import { LocationProductQueryParams } from '../../interfaces/LocationProductQueryParams';

interface LocationTableProps {
    location: ILocationWithPagination;
    loading: boolean;
    products: IProduct[];
    updateLocationProduct: (
        productId: string,
        locationId: string,
        values: EditProductFormProps,
    ) => Promise<void>;
    saveLocationProduct: (
        productId: string,
        locationId: string,
        valuedQuantity: string,
    ) => Promise<void>;
    getProducts(queryParams: ProductQueryParams): void;
    deleteLocationProduct(productId: string, locationId: string): void;
	setFilter: Dispatch<SetStateAction<LocationProductQueryParams>>;
}

const LocationTable = ({
	location,
	loading,
	products,
	updateLocationProduct,
	saveLocationProduct,
	getProducts,
	deleteLocationProduct,
	setFilter,
}: LocationTableProps): JSX.Element => {
	const [openEditModal, setOpenEditModal] = useState(false);
	const [openAddModal, setOpenAddModal] = useState(false);
	const [
		selectedLocationProduct,
		setSelectedLocationProduct,
	] = useState<ILocationProductTableRow | null>(null);
	const [selectedLocation, setSelectedLocation] = useState<ILocation | null>(null);
	const [formattedLocations, setFormattedLocations] = useState<ILocationProductTableRow[]>([]);
	const [
		expandedLocationId,
		setExpandedLocationId,
	] = useState<string | null>(null);

	const { requestConfirm, confirmationDialog } = useConfirmationDialog();

	const handleClose = useCallback((): void => {
		setOpenEditModal(false);
		setSelectedLocationProduct(null);
	}, []);

	const handleCloseAdd = useCallback((): void => {
		setOpenAddModal(false);
	}, []);

	const handleOpenModalAddProduct = useCallback((locationData: ILocation): void => {
		setSelectedLocation(locationData);
		setOpenAddModal(true);
	}, []);

	const handleExpandLocation = useCallback((locationData: ILocation) => {
		const rows = formatProductsByLocationTable(locationData);
		setFormattedLocations(rows);
	}, []);

	const handleOpenModalEditProduct = useCallback((
		locationProduct: ILocationProductTableRow,
	): void => {
		setSelectedLocationProduct(locationProduct);
		setOpenEditModal(true);
	}, []);

	const handleAddProduct = useCallback(async (
		values: AddProductFormProps,
		locationData: ILocation,
	) => {
		await saveLocationProduct(values.productId, locationData.id, values.valuedQuantity);
		setFilter((filter) => ({ ...filter }));
		handleCloseAdd();
	}, [handleCloseAdd, saveLocationProduct, setFilter]);

	const handleEditProduct = useCallback(async (
		productId: string,
		locationId: string,
		values: EditProductFormProps,
	) => {
		await updateLocationProduct(
			productId,
			locationId,
			values,
		);
		setFilter((filter) => ({ ...filter }));
		setOpenEditModal(false);
	}, [updateLocationProduct, setFilter]);

	const handleDeleteProduct = useCallback((row: ILocationProductTableRow): void => {
		deleteLocationProduct(
			row.locationProduct.productId,
			row.locationProduct.locationId,
		);
		setFilter((filter) => ({ ...filter }));
	}, [deleteLocationProduct, setFilter]);

	const columns = useMemo(() => [...locationProductsColumns,
		{
			field: 'actions',
			headerName: 'Ações',
			flex: 1,
			renderCell: (params: GridRenderCellParams) => (
				<>
					<GridActionsCellItem
						icon={<Edit />}
						label="Editar"
						color="primary"
						disabled={!params.row.currentQuantity}
						onClick={() => handleOpenModalEditProduct(params.row)}
					/>
					<GridActionsCellItem
						icon={<Delete />}
						label="Excluir"
						color="error"
						disabled={!params.row.currentQuantity}
						onClick={() => requestConfirm({
							description: 'Tem certeza que deseja remover o produto selecionado?',
							callback: () => handleDeleteProduct(params.row),
						})}
					/>
				</>
			),
		},
	], [handleDeleteProduct, handleOpenModalEditProduct, requestConfirm]);

	const updateExpandedLocationProducts = useCallback(() => {
		if (expandedLocationId) {
			const locationData = location.locations.find((loc) => loc.id === expandedLocationId);
			if (locationData) {
				const rows = formatProductsByLocationTable(locationData);
				setFormattedLocations(rows);
			}
		}
	}, [expandedLocationId, location]);

	useEffect(() => {
		updateExpandedLocationProducts();
	}, [location, updateExpandedLocationProducts]);

	if (location.locations.length === 0 && !loading) {
		return (
			<NoDataPage
				title="Nenhuma Localização Dísponivel"
				description="Crie uma localização para começar a visualizar."
			/>
		);
	}

	return (
		<>
			<Box>
				<AddProductModal
					open={openAddModal}
					onClose={handleCloseAdd}
					loading={loading}
					onSubmitAdd={handleAddProduct}
					getProducts={getProducts}
					location={selectedLocation}
					products={products}
				/>

				<EditProductModal
					open={openEditModal}
					onClose={handleClose}
					locationProduct={selectedLocationProduct}
					loading={loading}
					onSubmit={handleEditProduct}
				/>

				{location.locations.map((locationData) => (
					<Accordion
						key={locationData.id}
						expanded={expandedLocationId === locationData.id}
						onChange={(_event, isExpanded) => {
							setExpandedLocationId(isExpanded ? locationData.id : null);
							if (isExpanded) {
								handleExpandLocation(locationData);
							}
						}}
					>
						<AccordionSummary
							expandIcon={<ExpandMoreIcon />}
						>
							<Stack direction="row" alignItems="center" spacing={2}>
								<Typography>
									{ `${locationData.barCodeFormatted} - ${locationData.locationName}` }
								</Typography>
							</Stack>
						</AccordionSummary>
						<AccordionDetails>
							<DataGrid
								autoHeight
								rows={formattedLocations}
								columns={columns}
								loading={loading}
								hideFooter
								components={{
									Toolbar: ToolbarAddComponent(
										() => handleOpenModalAddProduct(locationData),
									),
								}}
							/>
						</AccordionDetails>
					</Accordion>
				))}
			</Box>
			{confirmationDialog}
		</>
	);
};

export default LocationTable;
