import React from 'react';
import { Stack } from '@mui/material';
import { FormTitle, PersonalData } from './UserFormSections';

export const EditUserForm = (): JSX.Element => (
	<Stack spacing={8}>
		<FormTitle>Editar usuário</FormTitle>
		<PersonalData />
	</Stack>
);
