import React, { useEffect } from 'react';
import {
	Paper,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	Typography,
} from '@mui/material';
import { useFormikContext } from 'formik';
import { ISalePriceFormation } from '../../../containers/SalePriceFormation/SalePriceFormationAssets';
import SwitchField from '../../Common/Form/Switch';
import Input from '../../Common/Form/Input';
import { currencyBRLMask } from '../../../helpers/intl';

interface DirectCostFormProps {
	isReadOnly: boolean;
}

const DirectCostForm = ({ isReadOnly }: DirectCostFormProps): JSX.Element => {
	const { values, setFieldValue } = useFormikContext<ISalePriceFormation>();

	useEffect(() => {
		const atLeastOneInformedFilled = values?.items.some(
			(directCost) => directCost.informed !== null && directCost.informed !== undefined,
		);

		if (atLeastOneInformedFilled) {
			const total = values?.items.reduce((acc, directCost) => {
				if (!directCost.composesCost) {
					return acc;
				}
				const cost = directCost.informed ? directCost.informed : directCost.averageCost;
				return acc + (directCost.quantity * cost);
			}, 0);
			setFieldValue('totalDirectCost', total);
		}
	}, [setFieldValue, values?.items]);

	return (
		<>
			<TableContainer component={Paper} sx={{ maxHeight: 500, mb: '10px' }}>
				<Table stickyHeader sx={{ width: '100%' }}>
					<TableHead>
						<TableRow>
							<TableCell sx={{ width: '30%' }}>Código/Descrição</TableCell>
							<TableCell sx={{ width: '10%' }}>Quantidade</TableCell>
							<TableCell sx={{ width: '10%' }}>Custo Médio</TableCell>
							<TableCell sx={{ width: '10%' }}>Custo Total</TableCell>
							<TableCell sx={{ width: '10%' }}>Último Preço</TableCell>
							<TableCell sx={{ width: '10%' }}>Informado</TableCell>
							<TableCell sx={{ width: '10%' }}>Compõe Custo</TableCell>
						</TableRow>
					</TableHead>
					<TableBody>
						{values?.items.map((directCost, index) => (
							<TableRow key={directCost.id || index}>
								<TableCell sx={{ padding: '6px 16px' }}>
									{directCost.code}
									{' '}
									-
									{' '}
									{directCost.description}
								</TableCell>
								<TableCell sx={{ padding: '6px 16px' }}>
									{directCost.quantity.toFixed(6).replace('.', ',')}
								</TableCell>
								<TableCell sx={{ padding: '6px 16px' }}>
									{currencyBRLMask(directCost.averageCost, { decimalPlaces: 3 })}
								</TableCell>
								<TableCell sx={{ padding: '6px 16px' }}>
									{currencyBRLMask(
										directCost.quantity * directCost.averageCost,
										{ decimalPlaces: 3 },
									)}
								</TableCell>
								<TableCell sx={{ padding: '6px 16px' }}>
									{currencyBRLMask(directCost.lastPrice, { decimalPlaces: 3 })}
								</TableCell>
								<TableCell sx={{ padding: '6px 16px' }}>
									<Input.FinancialInputField
										name={`items[${index}].informed`}
										sx={{
											height: '30px',
											margin: 0,
											'& .MuiInputBase-input': {
												height: '30px',
												padding: '0 5px',
											},
										}}
										decimalScale={3}
										disabled={isReadOnly}
									/>
								</TableCell>
								<TableCell sx={{ padding: '6px 16px' }}>
									<SwitchField
										name={`items[${index}].composesCost`}
										label="Não/Sim"
										disabled={isReadOnly}
									/>
								</TableCell>
							</TableRow>
						))}
					</TableBody>
				</Table>
			</TableContainer>
			<Typography variant="button" sx={{ fontSize: 17 }}>
				Custo Total:
				{' '}
				{currencyBRLMask(values.totalDirectCost, { decimalPlaces: 3 })}
			</Typography>
		</>
	);
};

export default DirectCostForm;
