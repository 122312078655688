import React, {
	useCallback, useEffect, useMemo, useState,
} from 'react';
import { Form, FormikContext, useFormik } from 'formik';
import { useNavigate } from 'react-router-dom';
import { ITask } from '../../../containers/Mobile/TaskAssets';
import ExpeditionStart from './ExpeditionStart';
import ExpeditionList from './ExpeditionList';
import ExpeditionProducts from './ExpeditionProducts';
import ExpeditionProductDetails from './ExpeditionProductDetails';
import { SaveTaskType } from '../../../services/expedition';
import { ISaleOrderLocations } from '../../../containers/Mobile/Expedition/ExpeditionAssets';
import ExpeditionFinish from './ExpeditionFinish';
import { groupTasksBySaleOrder } from '../../../helpers/Utils';

export enum StepsExpedition {
    START,
    PRODUCTS,
    LOCATIONS,
    PRODUCT_DETAILS,
    FINISH
}

const initialValues = {
	expeditionTasks: [],
	activeStep: StepsExpedition.START,
	locationSearch: '',
	productsSearch: '',
	locationDestinyConfirmation: '',
	locationDestiny: '',
	barcodeProduct: '',
	tasksPending: [],
	productQuantity: 0,
	productSelected: null,
	locationSelected: null,
};

interface ExpeditionProps {
    loading: boolean;
    getLocationDestiny: () => void;
    setLoading: (loading: boolean) => void;
    expeditionTasks: ITask[];
    saveTakeTaskBatch: (tasks: SaveTaskType[]) => void;
    saveTaskCountBatch: (task: SaveTaskType[]) => void;
}

export interface GroupedTasks {
    [saleOrder: string]: ISaleOrderLocations;
}

const Expedition = ({
	loading,
	setLoading,
	getLocationDestiny,
	saveTakeTaskBatch,
	saveTaskCountBatch,
	expeditionTasks,
}: ExpeditionProps): JSX.Element => {
	const [saleOrder, setSaleOrder] = useState<string>('');
	const [groupedTasks, setGroupedTasks] = useState<GroupedTasks | null>(null);
	const [tasksPendingView, setTasksPendingView] = useState<ITask[]>([]);
	const [tasksPendingPost, setTasksPendingPost] = useState<SaveTaskType[]>([]);
	const [locationSelected, setLocationSelected] = useState<string>('');
	const [isBarCode, setIsBarCode] = useState<boolean>(false);

	const navigate = useNavigate();

	useEffect(() => {
		getLocationDestiny();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		const grouped = groupTasksBySaleOrder(expeditionTasks);
		setGroupedTasks(grouped);
		setTasksPendingView(expeditionTasks);
	}, [expeditionTasks]);

	const formik = useFormik({
		initialValues,
		onSubmit: () => {
			saveTaskCountBatch(tasksPendingPost);
			formik.setFieldValue('activeStep', StepsExpedition.START);
		},
	});

	const setSteps = useCallback((step: StepsExpedition): void => {
		formik.setFieldValue('activeStep', step);
	}, [formik]);

	const handleLocationStep = useCallback((order: ISaleOrderLocations): void => {
		setLoading(true);
		const filteredTasks = expeditionTasks
			.filter((task) => order.saleOrder === task.inventoryTaskInvoice.saleOrder)
			.map((task) => ({ taskId: task.id, quantity: Number(task.quantity) }));
		setLoading(false);

		saveTakeTaskBatch(filteredTasks);
		setSaleOrder(order.saleOrder);
		setSteps(StepsExpedition.LOCATIONS);
	}, [expeditionTasks, saveTakeTaskBatch, setLoading, setSteps]);

	const handleExpeditionLocation = useCallback((location: string): void => {
		setLocationSelected(location);
		setSteps(StepsExpedition.PRODUCTS);
	}, [setSteps]);

	const handleProductDetails = useCallback((detailsBarCode: boolean): void => {
		setIsBarCode(detailsBarCode);
		setSteps(StepsExpedition.PRODUCT_DETAILS);
	}, [setSteps]);

	const handleFinishTask = useCallback((): void => {
		saveTaskCountBatch(tasksPendingPost);
		setSteps(StepsExpedition.START);
	}, [saveTaskCountBatch, tasksPendingPost, setSteps]);

	const handleConfirmQuantity = useCallback((task: SaveTaskType): void => {
		formik.setFieldValue('productQuantity', '');
		formik.setFieldValue('barcodeProduct', '');
		setTasksPendingPost((prevState) => [...prevState, task]);
		const updatedTasks = tasksPendingView.filter((t) => t.id !== task.taskId);
		setTasksPendingView(updatedTasks);

		const grouped = groupTasksBySaleOrder(updatedTasks);
		setGroupedTasks(grouped);

		if (!grouped[saleOrder]) {
			setSteps(StepsExpedition.FINISH);
			return;
		}
		if (!grouped[saleOrder].locations[locationSelected]) {
			setSteps(StepsExpedition.LOCATIONS);
			return;
		}
		if (grouped[saleOrder].locations[locationSelected].tasks.length > 0) {
			setSteps(StepsExpedition.PRODUCTS);
		}
	}, [formik, saleOrder, locationSelected, setSteps, tasksPendingView]);

	const memoizedGroupedTasks = useMemo(() => groupedTasks, [groupedTasks]);
	const memoizedTasksPendingPost = useMemo(() => tasksPendingPost, [tasksPendingPost]);

	return (
		<FormikContext.Provider value={formik}>
			<Form>
				{formik.values.activeStep === StepsExpedition.START && (
					<ExpeditionStart
						loading={loading}
						handleLocationStep={handleLocationStep}
						groupedTasks={memoizedGroupedTasks}
						handleBackStart={() => navigate('/app')}
					/>
				)}

				{formik.values.activeStep === StepsExpedition.LOCATIONS && (
					<ExpeditionList
						loading={loading}
						saleOrder={saleOrder}
						groupedTasks={memoizedGroupedTasks}
						handleExpeditionLocation={handleExpeditionLocation}
						handleBackStart={() => setSteps(StepsExpedition.START)}
					/>
				)}

				{formik.values.activeStep === StepsExpedition.PRODUCTS && (
					<ExpeditionProducts
						loading={loading}
						saleOrder={saleOrder}
						location={locationSelected}
						groupedTasks={memoizedGroupedTasks}
						handleProductDetails={handleProductDetails}
						handleBackList={() => setSteps(StepsExpedition.LOCATIONS)}
					/>
				)}

				{formik.values.activeStep === StepsExpedition.PRODUCT_DETAILS && (
					<ExpeditionProductDetails
						loading={loading}
						location={locationSelected}
						handleConfirmQuantity={handleConfirmQuantity}
						saleOrder={saleOrder}
						isBarCode={isBarCode}
					/>
				)}

				{formik.values.activeStep === StepsExpedition.FINISH && (
					<ExpeditionFinish
						saleOrder={saleOrder}
						handleFinishTask={handleFinishTask}
						tasksPendingPost={memoizedTasksPendingPost}
					/>
				)}
			</Form>
		</FormikContext.Provider>
	);
};

export default Expedition;
