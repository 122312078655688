import React, { useMemo } from 'react';
import { Form, Formik } from 'formik';
import { Box, Grid } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import Input from '../Common/Form/Input';
import {
	initialValues,
	validationSchema,
} from '../../containers/LocationType/LocationTypeAssets';
import { Loading } from '../Common/Loading';
import { ILocationType } from '../../containers/Location/LocationAssets';

interface LocationTypeFormProps {
  onSubmit: (values: ILocationType) => void;
  loading: boolean;
  locations: Array<ILocationType>;
  locationType?: ILocationType;
}

const LocationTypeForm = ({
	onSubmit,
	locationType,
	loading,
	locations,
}: LocationTypeFormProps): JSX.Element => {
	const initialValuesLoad = useMemo(
		() => (locationType
			? {
				id: locationType.id,
				name: locationType.name,
				prefix: locationType.prefix,
				level: locationType.level,
				canEditPrefix: locationType.canEditPrefix,
			}
			: initialValues),
		[locationType],
	);

	const formatValueLevel = (value: string): number => {
		let valueFormatted = 0;
		const regexValidStringAndBackSpace = /^[a-zA-Z\s]+$/;

		if (!regexValidStringAndBackSpace.test(value)) {
			valueFormatted = Number.parseInt(value, 10);
		}

		return valueFormatted;
	};

	return (
		<>
			{loading && <Loading />}
			{!loading && (
				<Formik
					initialValues={initialValuesLoad}
					onSubmit={onSubmit}
					validationSchema={validationSchema({
						locations,
					})}
					validateOnChange={false}
					validateOnBlur={false}
					enableReinitialize
				>
					<Form>
						<Grid container spacing={2} paddingTop={4}>
							<Grid item xs={12} md={2}>
								<Input.InputField
									disabled={!(initialValuesLoad.canEditPrefix)}
									name="prefix"
									label="Prefixo"
									format={(value) => value.toUpperCase()}
								/>
							</Grid>
							<Grid item xs={12} md={8}>
								<Input.InputField name="name" label="Nome" />
							</Grid>
							<Grid item xs={12} md={2}>
								<Input.InputField
									name="level"
									label="Nível"
									type="number"
									sx={{
										'& input[type=number]': {
											MozAppearance: 'textfield',
										},
										'& input[type=number]::-webkit-outer-spin-button, & input[type=number]::-webkit-inner-spin-button': {
											WebkitAppearance: 'none',
											margin: 0,
										},
									}}
									format={formatValueLevel}
								/>
							</Grid>
						</Grid>
						<Box marginTop={4}>
							<LoadingButton
								loading={loading}
								type="submit"
								variant="contained"
								color="primary"
							>
								Salvar
							</LoadingButton>
						</Box>
					</Form>
				</Formik>
			)}
		</>
	);
};

LocationTypeForm.defaultProps = {
	locationType: undefined,
};

export default LocationTypeForm;
