import {
	useState,
	useCallback,
	useEffect,
} from 'react';
import { GridSortModel } from '@mui/x-data-grid';
import { normalizeDataGridOrderBy } from '../helpers/Utils';

interface useDataGridManagementParams<Q> {
    initialPageSize?: number;
    initialPage?: number;
    initialSortModel?: GridSortModel;
	initialFilterValues?: Q;
    fetchData: (queryParams: Q) => void;
}

interface useDataGridManagementResult<Q> {
    filter: Q;
    sortModel: GridSortModel;
    setFilter: React.Dispatch<React.SetStateAction<Q>>;
    setSortModel: React.Dispatch<React.SetStateAction<GridSortModel>>;
    onChangePage: (page: number) => void;
    onChangePageSize: (pageSize: number) => void;
    onSortModelChange: (newSortModel: GridSortModel) => void;
}

function useDataGridManagement<Q>(
	params: useDataGridManagementParams<Q>,
): useDataGridManagementResult<Q> {
	const {
		initialSortModel = [],
		initialPageSize = 10,
		initialPage = 0,
		initialFilterValues,
		fetchData = false,
	} = params;

	const initialFilter = initialFilterValues || {
		skip: initialPage,
		take: initialPageSize,
		orderBy: normalizeDataGridOrderBy(initialSortModel),
	} as Q;

	const [filter, setFilter] = useState<Q>(initialFilter as unknown as Q);

	const [sortModel, setSortModel] = useState<GridSortModel>(initialSortModel);

	useEffect(() => {
		if (fetchData) {
			params.fetchData(filter);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [filter]);

	const onChangePage = useCallback((page: number) => {
		setFilter((f) => ({ ...f, skip: page } as Q));
	}, []);

	const onChangePageSize = useCallback((pageSize: number) => {
		setFilter((f) => ({ ...f, take: pageSize } as Q));
	}, []);

	const onSortModelChange = useCallback((newSortModel: GridSortModel) => {
		setFilter((f) => ({ ...f, orderBy: normalizeDataGridOrderBy(newSortModel) } as Q));
		setSortModel(newSortModel);
	}, []);

	return {
		filter,
		sortModel,
		setFilter,
		setSortModel,
		onChangePage,
		onChangePageSize,
		onSortModelChange,
	};
}

export default useDataGridManagement;
