import * as Yup from 'yup';
import { validationMessage } from '../../helpers/yupMessages';
import { LocationDataParams } from './LocationType';
import { ILocationType } from '../Location/LocationAssets';

export const initialValues: ILocationType = {
	id: undefined,
	name: '',
	prefix: '',
	level: 0,
	canEditPrefix: true,
};

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
export const validationSchema = (props: Partial<LocationDataParams>) => Yup.object().shape({
	id: Yup.string().nullable(),
	canEditPrefix: Yup.boolean(),
	name: Yup.string().required(validationMessage.required),
	prefix: Yup.string()
		.max(1, validationMessage.string.max(1))
		.required(validationMessage.required)
		.matches(
			/^[a-zA-Z0-9]+$/,
			'O prefixo deve conter apenas letras e números',
		)
		.test(
			'unique-prefix',
			'O prefixo informado já existe',
			(value, context) => {
				const prefixValue = value || '';
				const { canEditPrefix, id } = context.parent as ILocationType;

				if (!canEditPrefix) {
					return true;
				}

				const locations = props.locations || [];

				return !locations.some(
					(location) => location.prefix.toLowerCase() === prefixValue.toLowerCase()
              && location.id !== id,
				);
			},
		),
	level: Yup.number()
		.min(0, validationMessage.number.min(0))
		.required(validationMessage.required),
});
