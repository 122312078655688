import * as yup from 'yup';
import { ConversionType } from '../../enums/ConversionType';
import { ProductType } from '../../interfaces/Product';

export interface IProduct {
  id: string;
  code: string;
  description: string;
  barCode: string;
  typeId: string;
  qualityControl: boolean;
  locateControl: boolean;
  apportionmentControl: boolean;
  companyId?: string;
  branchId?: string;
  accountingAccountId?: string;
  conversionType?: ConversionType;
  conversionIndex: number;
  erpCode?: string;
  erpDescription?: string;
  primaryMeasureId?: string;
  secondaryMeasureId?: string;
  measureIds?: string[];
  image?: File;
  imageB64?: string;
  measureId: string;
  automaticPurchaseOrder: boolean;
  commissionValue: number;
  partial: number[];
}

export interface IProductWithoutDetails {
  id: string;
  code: string;
  description: string;
}

export interface ProductSaleOrder {
  id: string;
  type: ProductType;
  description: string;
  amount: number;
  price: number;
  commissionValue: number;
  commission: number;
  discount: number;
  expanded?: boolean;
  totalWithoutDiscount: number;
  totalWithDiscount: number;
  commissionAmount: number;
}
export interface EditProduct {
  id?: string;
  code: string;
  description: string;
  barCode: string;
  typeId: string;
  qualityControl: boolean;
  locateControl: boolean;
  apportionmentControl: boolean;
  companyId?: string;
  branchId?: string;
  accountingAccountId?: string;
  conversionType?: ConversionType;
  conversionIndex?: number;
  erpCode?: string;
  erpDescription?: string;
  primaryMeasureId?: string;
  secondaryMeasureId?: string;
  measureIds?: string[];
  image: { file: File | null; preview: string | null } | null;
}

export interface IProducts {
  count: number;
  data: IProduct[];
}

export interface IProductsWithoutDetails {
  count: number;
  data: IProductWithoutDetails[];
}

export interface IMeasures {
  count: number;
  data: IMeasure[];
}
export interface IProductTypes {
  count: number;
  data: IProductType[];
}

export interface IMeasure {
  id: string;
  description: string;
  erpCode?: string;
  erpDescription?: string;
}

export interface IProductType {
  id: string;
  description: string;
  erpCode?: string;
  erpDescription?: string;
}

export interface ILocationProductData {
  id: string;
  barCode: string;
  name: string;
  locationName: string;
  product: IProductLocation;
}

export interface IProductLocation {
  id: string;
  valuedQuantity: string;
  currentQuantity: string;
}

export const initialValues: EditProduct = {
	code: '',
	description: '',
	barCode: '',
	primaryMeasureId: '',
	secondaryMeasureId: '',
	typeId: '',
	qualityControl: false,
	locateControl: false,
	apportionmentControl: false,
	conversionType: ConversionType.DIVIDER,
	conversionIndex: 0,
	erpCode: '',
	erpDescription: '',
	accountingAccountId: '',
	image: null,
};

export const validationSchema = yup.object({
	code: yup
		.string()
		.min(5, 'O código deve ter no mínimo 5 dígitos')
		.required('Código é obrigatório!'),

	description: yup
		.string()
		.test(
			'not-only-numbers',
			'A descrição não pode ser numérico',
			(value) => !/^\d+$/.test(value || ''),
		)
		.required('Descrição é obrigatória!'),

	barCode: yup.string().required('Código de barras é obrigatório!'),

	primaryMeasureId: yup
		.string()
		.required('1ª Unidade de medida é obrigatória!'),

	typeId: yup.string().required('Tipo é obrigatório!'),
});
