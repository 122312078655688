import React, { useEffect, useRef } from 'react';
import {
	Box,
	Typography,
	Button,
	IconButton,
	Grid,
	Stack,
} from '@mui/material';
import {
	Add,
	Remove,
} from '@mui/icons-material';
import { useFormikContext } from 'formik';
import { useSnackbar } from 'notistack';
import Input from '../../Common/Form/Input';
import { Loading } from '../../Common/Loading';
import { SaveTaskType } from '../../../services/expedition';
import { ITask } from '../../../containers/Mobile/TaskAssets';
import { formatBarCode } from '../../../helpers/masks';

interface ExpeditionProductDetailsProps {
	saleOrder: string;
	loading: boolean;
	location: string;
	handleConfirmQuantity: (task: SaveTaskType) => void;
	isBarCode: boolean;
}

const ExpeditionProductDetails = ({
	loading,
	location,
	handleConfirmQuantity,
	saleOrder,
	isBarCode,
}:ExpeditionProductDetailsProps): JSX.Element => {
	const { values, setFieldValue } = useFormikContext<{
		productQuantity: number;
		productSelected: ITask;
		locationSelected: string;
	}>();

	const { enqueueSnackbar } = useSnackbar();
	const inputRef = useRef<HTMLInputElement>(null);

	useEffect(() => {
		if (inputRef.current) {
			inputRef.current.focus();
		}

		if (isBarCode) {
			setFieldValue('productQuantity', 1);
		} else {
			setFieldValue('productQuantity', 0);
		}
	}, [saleOrder, isBarCode, setFieldValue]);

	if (loading) {
		<Loading />;
	}

	const handleIncrement = ():void => {
		setFieldValue('productQuantity', (values.productQuantity ?? 0) + 1);
	};

	const handleDecrement = ():void => {
		setFieldValue('productQuantity', (values.productQuantity ?? 0) - 1);
	};

	const handleBlurOrEnter = (event: React.KeyboardEvent<HTMLInputElement>): void => {
		const target = event.target as HTMLInputElement;
		if ((event.key === 'Tab') || (event.key === 'Enter')) {
			event.preventDefault();
			if (values.productSelected.product.code === target.value) {
				handleIncrement();
			} else {
				enqueueSnackbar('Código de barras inválido!', {
					variant: 'error',
				});
			}
			setFieldValue('barcodeProduct', '');
		}
	};

	return (
		<Box padding={1}>
			<Typography variant="subtitle1" align="center">
				{`Localização: ${formatBarCode(location)}`}
			</Typography>
			<Typography variant="subtitle1" align="center" sx={{ marginBottom: 2 }}>
				{`Pedido de Venda: ${saleOrder}`}
			</Typography>
			<Box
				sx={{
					padding: 2, border: 1, borderColor: '#bbb', bgcolor: 'white', borderRadius: 2, mb: 2, display: 'flex', justifyContent: 'space-between',
				}}
			>
				<Stack direction="column">
					<Typography variant="subtitle1" align="center">
						{`${values.productSelected.product.code} - ${values.productSelected.product.description}`}
					</Typography>
					<Typography variant="subtitle1" align="center" sx={{ marginBottom: 2, fontWeight: 'bold' }}>
						{`${values.productSelected.quantity} ${values.productSelected.product.measures[0].description}(s)`}
					</Typography>
					<Grid container spacing={2}>
						<Grid item xs={12} sm={6}>
							<Input.InputField
								label="Código de Barras"
								id="barcodeProduct"
								name="barcodeProduct"
								onKeyDown={(
									event: React.KeyboardEvent<HTMLInputElement>,
								) => handleBlurOrEnter(event)}
								inputRef={inputRef}
								fullWidth
							/>
						</Grid>
						<Grid item xs={12} sm={6}>
							<Box
								sx={{
									width: '100%',
									display: 'flex',
									alignItems: 'center',
									justifyContent: 'center',
								}}
							>
								<IconButton disabled={values.productQuantity === 0} size="large" onClick={handleDecrement}>
									<Remove />
								</IconButton>

								<Input.InputField
									id="productQuantity"
									name="productQuantity"
									style={{ width: 100, textAlign: 'center' }}
									type="number"
									required
								/>
								<IconButton size="large" onClick={handleIncrement}>
									<Add />
								</IconButton>
							</Box>
						</Grid>
						<Grid item xs={12} sm={6} display="flex" justifyContent="space-between" marginTop={2} gap={2}>
							<Button
								variant="contained"
								size="small"
								color="primary"
								sx={{ width: '100%' }}
								disabled={
									Number(values.productQuantity) > Number(values.productSelected.quantity)
								}
								onClick={() => handleConfirmQuantity({
									quantity: Number(values.productQuantity),
									taskId: values.productSelected.id,
								})}
							>
								Confirmar
							</Button>
						</Grid>
					</Grid>
				</Stack>
			</Box>
		</Box>
	);
};

export default ExpeditionProductDetails;
