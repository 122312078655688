import React, { useEffect, useRef } from 'react';
import {
	Box,
	Typography,
	Button,
	Paper,
} from '@mui/material';
import { Formik, useFormikContext } from 'formik';
import { useSnackbar } from 'notistack';
import Input from '../../Common/Form/Input';
import { SaveTaskType } from '../../../services/expedition';

interface ExpeditionListProps {
	tasksPendingPost: SaveTaskType[]
	handleFinishTask(data: SaveTaskType[]): void;
	saleOrder: string;
}

const ExpeditionFinish = ({
	tasksPendingPost,
	saleOrder,
	handleFinishTask,
}: ExpeditionListProps): JSX.Element => {
	const { values } = useFormikContext<{
		locationDestiny: string;
		locationDestinyConfirmation: string;
	}>();
	const { enqueueSnackbar } = useSnackbar();
	const inputRef = useRef<HTMLInputElement>(null);

	const handleFinishTasks = ():void => {
		if (values.locationDestiny === values.locationDestinyConfirmation) {
			handleFinishTask(tasksPendingPost);
		} else {
			enqueueSnackbar('Localização de destino inválida.', {
				variant: 'error',
			});
		}
	};

	const handleBlurOrEnter = (event: React.KeyboardEvent<HTMLInputElement>): void => {
		if ((event.key === 'Tab') || (event.key === 'Enter')) {
			event.preventDefault();
			handleFinishTasks();
		}
	};

	useEffect(() => {
		if (inputRef.current) {
			inputRef.current.focus();
		}
	}, [tasksPendingPost]);

	return (
		<Box padding={1} sx={{ mt: 4 }}>
			<Typography variant="h6" align="center">
				Confirme a Localização Destino
			</Typography>

			<Typography align="center">
				{`Localização de Destino: ${values.locationDestiny}`}
			</Typography>

			<Typography align="center">
				{`Pedido de Venda: ${saleOrder}`}
			</Typography>

			<Paper sx={{ boxShadow: 'none', marginTop: 6, marginBottom: 2 }}>
				<Input.InputField
					id="locationDestinyConfirmation"
					name="locationDestinyConfirmation"
					label="Digite o código da localização"
					inputRef={inputRef}
					autoComplete="off"
					InputProps={{
						inputProps: {
							min: 0,
							step: 'any',
						},
					}}
					fullWidth
					format={(value) => value.toUpperCase()}
					onKeyDown={handleBlurOrEnter}
				/>
			</Paper>
			<Button
				sx={{ mt: 2, width: '100%' }}
				size="large"
				variant="contained"
				type="submit"
			>
				Confirmar
			</Button>
		</Box>
	);
};

export default ExpeditionFinish;
