import React, { useState } from 'react';
import { Form, FormikContext, useFormik } from 'formik';
import InventoryProduct from './InventoryProduct';
import InventoryLocation from './InventoryLocation';
import InventoryQuantity from './InventoryQuantity';

export enum StepsInventory {
    PRODUCT,
	LOCATION,
	QUANTITY
}

const initialValues = {
	productBarCode: '',
	activeStep: StepsInventory.PRODUCT,
};

const Inventory = ():JSX.Element => {
	const [secondConference, setSecondConference] = useState(false);

	const formik = useFormik({
		initialValues,
		onSubmit: () => {
			console.log('tasksPendingPost');
		},
	});

	const handleConfirmProduct = ():void => {
		formik.setFieldValue('activeStep', StepsInventory.LOCATION);
	};

	const handleConfirmLocation = ():void => {
		formik.setFieldValue('activeStep', StepsInventory.QUANTITY);
	};

	const handleFinish = ():void => {
		setSecondConference(true);
		formik.setFieldValue('activeStep', StepsInventory.PRODUCT);
	};

	return (
		<FormikContext.Provider value={formik}>
			<Form>
				{formik.values.activeStep === StepsInventory.PRODUCT && (
					<InventoryProduct
						handleConfirmProduct={handleConfirmProduct}
						secondConference={secondConference}
					/>
				)}

				{formik.values.activeStep === StepsInventory.LOCATION && (
					<InventoryLocation
						handleConfirmLocation={handleConfirmLocation}
						secondConference={secondConference}
					/>
				)}

				{formik.values.activeStep === StepsInventory.QUANTITY && (
					<InventoryQuantity
						handleFinish={handleFinish}
						secondConference={secondConference}
					/>
				)}
			</Form>
		</FormikContext.Provider>
	);
};

export default Inventory;
