import React from 'react';
import {
	Card,
	CardContent,
	Typography,
	Grid,
} from '@mui/material';
import {
	Autorenew, Inventory2, Warehouse,
	LocalShipping,
} from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';

const HomeMobile = (): JSX.Element => {
	const navigate = useNavigate();

	return (
		<Grid container spacing={2}>
			<Grid item mb={2} xs={6}>
				<Card onClick={() => navigate('storage-check')}>
					<CardContent>
						<Grid container direction="column" alignItems="center">
							<Warehouse fontSize="large" color="primary" />
							<Typography align="center">Conferências Pendentes</Typography>
							{/* <Typography variant="h4" align="center">10</Typography> */}
						</Grid>
					</CardContent>
				</Card>
			</Grid>

			<Grid item mb={2} xs={6}>
				<Card onClick={() => navigate('storage')}>
					<CardContent>
						<Grid container direction="column" alignItems="center">
							<Warehouse fontSize="large" color="primary" />
							<Typography align="center">Armazenagens Pendentes</Typography>
							{/* <Typography variant="h4" align="center">10</Typography> */}
						</Grid>
					</CardContent>
				</Card>
			</Grid>

			<Grid item xs={12}>
				<Card onClick={() => navigate('expedition')}>
					<CardContent>
						<Grid container direction="column" alignItems="center">
							<LocalShipping fontSize="large" color="primary" />
							<Typography align="center">Expedições Pendentes</Typography>
							{/* <Typography variant="h4" align="center">5</Typography> */}
						</Grid>
					</CardContent>
				</Card>
			</Grid>

			<Grid item xs={12}>
				<Card onClick={() => navigate('transfer')}>
					<CardContent>
						<Grid container direction="column" alignItems="center">
							<Autorenew fontSize="large" color="primary" />
							<Typography align="center">Transferências Pendentes</Typography>
							{/* <Typography variant="h4" align="center">5</Typography> */}
						</Grid>
					</CardContent>
				</Card>
			</Grid>

			<Grid item xs={12}>
				<Card onClick={() => navigate('inventory')}>
					<CardContent>
						<Grid container direction="column" alignItems="center">
							<Inventory2 fontSize="large" color="primary" />
							<Typography align="center">Inventário</Typography>
						</Grid>
					</CardContent>
				</Card>
			</Grid>
		</Grid>
	);
};

export default HomeMobile;
