/* eslint-disable react/jsx-props-no-spreading */
import React, { useMemo } from 'react';
import {
	TextField,
	Autocomplete as MuiAutocomplete,
	AutocompleteProps,
	CircularProgress,
} from '@mui/material';
import { useField } from 'formik';

interface IAutocompleteProps<T>
  extends Omit<
    AutocompleteProps<any, boolean, undefined, undefined, 'div'>,
    'renderInput'
  > {
  options: T[];
  labelKey: keyof T;
  valueKey: keyof T;
  label: string;
  name: string;
  valueLabel?: keyof T;
  required?: boolean;
  disableAllOptions?: boolean;
  disableOptions?: string[];
  closeOnSelect?: boolean;
  loading?: boolean;
}

const Autocomplete = <T, >({
	options,
	labelKey,
	valueKey,
	valueLabel,
	label,
	name,
	required,
	disableAllOptions,
	disableOptions,
	closeOnSelect,
	loading,
	...props
}: IAutocompleteProps<T>): JSX.Element => {
	const [field, meta, helpers] = useField({ name });

	const optionsValues = useMemo(
		() => options.map((option) => option[valueKey]),
		[options, valueKey],
	);

	const error = useMemo(() => {
		if (Boolean(meta.touched) && Boolean(meta.error)) {
			return meta.error;
		}

		return null;
	}, [meta.error, meta.touched]);

	const handleChange = (event: any, newValue: any): void => {
		helpers.setValue(newValue);
	};

	return (
		<MuiAutocomplete
			{...field}
			autoHighlight
			disableCloseOnSelect={!closeOnSelect}
			filterSelectedOptions
			options={optionsValues}
			loading={loading}
			onChange={handleChange}
			renderInput={(params) => (
				<TextField
					{...params}
					label={label}
					placeholder={label}
					required={required}
					error={Boolean(error)}
					helperText={error}
					InputProps={{
						...params.InputProps,
						endAdornment: (
							<>
								{loading ? (
									<CircularProgress color="inherit" size={26} />
								) : null}
								{params.InputProps.endAdornment}
							</>
						),
					}}
				/>
			)}
			getOptionDisabled={(option) => disableAllOptions
				|| (disableOptions || []).includes(option)
				|| false}
			getOptionLabel={(option) => {
				const optionObject = options.find((item) => item[valueKey] === option);

				if (optionObject) {
					return `${valueLabel ? optionObject?.[valueLabel] : option} - ${
						optionObject?.[labelKey]
					}`;
				}

				return '';
			}}
			noOptionsText={loading ? 'Carregando...' : 'Não há opções'}
			{...props}
		/>
	);
};

Autocomplete.defaultProps = {
	valueLabel: null,
	required: false,
	disableAllOptions: false,
	disableOptions: [],
	closeOnSelect: false,
	loading: false,
};

export default Autocomplete;
