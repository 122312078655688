import { AxiosResponse } from 'axios';
import api from './api';
import {
	IBranch,
	IBranchResponse,
} from '../containers/Branch/BranchAssets';

export const getBranches = (): Promise<AxiosResponse<IBranchResponse>> => api.get<IBranchResponse>('/branch');

export const getBranchById = (
	id: string,
	companyId: string,
): Promise<AxiosResponse<IBranch>> => api.get<IBranch>(`branch/${id}/${companyId}`);

export const saveBranch = (
	data: FormData,
): Promise<AxiosResponse> => api.post<FormData, AxiosResponse>('/branch', data, {
	headers: { 'Content-Type': 'multipart/form-data' },
});

export const updateBranch = (
	id: string,
	data: FormData,
): Promise<AxiosResponse> => api.patch(`/branch/${id}`, data, {
	headers: { 'Content-Type': 'multipart/form-data' },
});

export const deleteBranch = (
	id: string,
	companyId: string,
): Promise<AxiosResponse> => api.delete(`/branch/${id}/${companyId}`);
