import React, { useCallback, useMemo } from 'react';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import IconButton from '@mui/material/IconButton';
import { Link } from 'react-router-dom';
import {
	DataGrid,
	GridColDef,
	GridActionsCellItem,
	GridValueFormatterParams,
	GridCellParams,
	GridSortModel,
} from '@mui/x-data-grid';
import Tooltip from '@mui/material/Tooltip';
import EditIcon from '@mui/icons-material/Edit';
import ToggleOffIcon from '@mui/icons-material/ToggleOff';
import ToggleOnIcon from '@mui/icons-material/ToggleOn';
import PasswordIcon from '@mui/icons-material/Password';
import { User, CurrentUserProps } from '../../containers/User/UserAssets';

interface CustomValueFormatterParams extends GridValueFormatterParams<unknown> {
	value: number | string;
}

interface UserTableProps {
	loading?: boolean;
	rows: User[];
	usersPages: number;
	usersPage: number;
	usersTake: number;
	sortModel: GridSortModel;
	onChangePage(usersTake: number): void;
	onChangePageSize(pageSize: number): void;
	onSortModelChange(sortModel: GridSortModel): void;
	onResetPassword: (user: CurrentUserProps) => void;
	onChangeActiveStatus(id: string, active: boolean): void;
}

const UserTable = ({
	rows,
	loading,
	usersPages,
	usersPage,
	usersTake,
	sortModel,
	onChangePage,
	onChangePageSize,
	onSortModelChange,
	onResetPassword,
	onChangeActiveStatus,
}: UserTableProps): JSX.Element => {
	const renderToggleActiveButton = useCallback((params: GridCellParams): JSX.Element => {
		const isActive = params.row.active;
		const onClick = (): void => onChangeActiveStatus(params.id as string, !isActive);
		const color = isActive ? 'primary' : 'default';
		const tooltipTitle = isActive ? 'Bloquear usuário' : 'Desbloquear usuário';
		const actionLabel = isActive ? 'Bloquear usuário' : 'Desbloquear usuário';
		const IconComponent = isActive ? ToggleOnIcon : ToggleOffIcon;

		return (
			<Tooltip title={tooltipTitle}>
				<GridActionsCellItem
					label={actionLabel}
					icon={<IconComponent />}
					color={color}
					onClick={onClick}
				/>
			</Tooltip>
		);
	}, [onChangeActiveStatus]);

	const columns: GridColDef[] = useMemo(() => [
		{ field: 'name', headerName: 'Nome', flex: 1.5 },
		{
			field: 'login', headerName: 'Login', flex: 1, sortable: false,
		},
		{
			field: 'email', headerName: 'Email', flex: 1.5, sortable: false,
		},
		{
			field: 'phone', headerName: 'Telefone', flex: 1, sortable: false,
		},
		{
			field: 'createdAt',
			headerName: 'Adicionado em',
			flex: 1,
			sortable: false,
			valueFormatter: (params: CustomValueFormatterParams) => {
				const date = new Date(params.value);
				return (
					date.toLocaleString('pt-BR')
				);
			},
		},
		{
			field: 'actions',
			headerName: 'Ações',
			width: 140,
			type: 'action',
			sortable: false,
			renderCell: (params: GridCellParams) => (
				<>
					<Tooltip title="Editar">
						<Link to={`edit/${params.id}`}>
							<GridActionsCellItem
								label="Editar"
								icon={<EditIcon />}
							/>
						</Link>
					</Tooltip>
					<Tooltip title="Redefinir senha">
						<GridActionsCellItem
							label="Redefinir senha"
							onClick={() => onResetPassword(params.row as CurrentUserProps)}
							icon={<PasswordIcon />}
						/>
					</Tooltip>
					{renderToggleActiveButton(params)}
				</>
			),
		},

	], [onResetPassword, renderToggleActiveButton]);

	return (
		<Box component={Paper} sx={{ width: '100%' }}>
			<DataGrid
				autoHeight
				rows={rows}
				rowCount={usersPages}
				pagination
				paginationMode="server"
				sortingMode="server"
				columns={columns}
				page={usersPage}
				pageSize={usersTake}
				rowsPerPageOptions={[10, 25, 50, 100]}
				loading={loading}
				sortModel={sortModel}
				onPageChange={onChangePage}
				onPageSizeChange={onChangePageSize}
				onSortModelChange={onSortModelChange}
				disableSelectionOnClick
				disableColumnMenu
			/>
		</Box>
	);
};

UserTable.defaultProps = {
	loading: false,
};

export default UserTable;
