import React, {
	useCallback, useEffect, useState, useMemo,
} from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import IconButton from '@mui/material/IconButton';
import TextField, { TextFieldProps } from '@mui/material/TextField';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import TimerIcon from '@mui/icons-material/Timer';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import DeleteIcon from '@mui/icons-material/Delete';
import LoadingButton from '@mui/lab/LoadingButton';
import { useSearchParams } from 'react-router-dom';
import { format } from 'date-fns';
import Tooltip from '@mui/material/Tooltip';
import Button from '@mui/material/Button';
import Alert from '@mui/material/Alert';
import { PageHeader } from '../Common/PageHeader/PageHeader';
import { UserInfo, UserTasksAppointedHoursParams } from '../../containers/HourProject/UserTasksAppointedHoursAssets';
import { HourProject, TaskClockInOut } from '../../containers/HourProject/ApportionmentAssets';
import { Loading } from '../Common/Loading';
import { formatDate, formatDateAndHours } from '../../helpers/Utils';
import ERPClockHoursTable from './ERPClockHoursTable';
import { ERPClockHoursMap } from '../../containers/HourProject/ClockHoursAssets';
import DayCarousel from '../Common/DayCarousel';

interface UserTasksAppointedHoursProps {
	loading: boolean;
	userInfo: UserInfo;
	projects: HourProject[];
	erpClockInOuts: ERPClockHoursMap;
	erpClockInOutsCount: number;
	getUserTasksAppointments(queryParams: UserTasksAppointedHoursParams): void;
	createClockAppointment(data: TaskClockInOut): void;
	updateClockAppointment(id: string, data: TaskClockInOut): void;
	removeClockAppointment(id: string): void;
	sendApprovalIds(taskClockInOutIds: string[]): void;
	taskClockInOutIds: string[];
	hasApproval: boolean;
}

const textFieldStyle = {
	width: 100,
	'& .MuiInput-underline:before, & .MuiInput-underline': {
		borderBottomColor: '#b71c1c',
		borderBottomWidth: '2px',
	},
};

const UserTasksAppointedHours = (props: UserTasksAppointedHoursProps): JSX.Element => {
	const {
		loading,
		userInfo,
		projects,
		erpClockInOuts,
		erpClockInOutsCount,
		getUserTasksAppointments,
		createClockAppointment,
		updateClockAppointment,
		removeClockAppointment,
		sendApprovalIds,
		taskClockInOutIds,
		hasApproval,
	} = props;
	const [updateAppointments, setUpdateAppointments] = useState<{ [key: string]: Date | null }>({});
	const [insertAppointments, setInsertAppointments] = useState<any[]>([]);
	const [searchParams, setSearchParams] = useSearchParams();
	const date = useMemo(() => searchParams.get('date') || '', [searchParams]);
	const userId = useMemo(() => searchParams.get('userId') || '', [searchParams]);

	useEffect(() => {
		if (date && userId) {
			getUserTasksAppointments({ date, userId });
		}
	}, [date, userId, getUserTasksAppointments]);

	const onChangeAppointment = useCallback((
		taskId: string,
		value: Date | null,
		clockInOutId?: string,
	) => {
		if (clockInOutId) {
			setUpdateAppointments((prev) => ({
				...prev,
				[clockInOutId]: value,
			}));
		} else {
			setInsertAppointments((prev) => ({
				...prev,
				[taskId]: value,
			}));
		}
	}, []);

	const sendAppointment = useCallback((
		taskId: string,
		clockInOutId: string,
	) => {
		const updatedTime = updateAppointments[clockInOutId];
		if (!updatedTime) return;

		const updatedData: TaskClockInOut = {
			dateTime: updatedTime,
			projectTaskId: taskId,
			userId,
		};

		updateClockAppointment(clockInOutId, updatedData);

		setUpdateAppointments((prev) => {
			const newState = { ...prev };
			delete newState[clockInOutId];
			return newState;
		});
	}, [updateAppointments, userId, updateClockAppointment]);

	const sendNewAppointment = useCallback((taskId: string) => {
		const appointmentTime = insertAppointments[taskId as any];

		if (!appointmentTime) {
			return;
		}

		const baseDate = new Date(formatDate(date));
		const dateTime = new Date(
			baseDate.getFullYear(),
			baseDate.getMonth(),
			baseDate.getDate(),
			appointmentTime.getHours(),
			appointmentTime.getMinutes(),
		);

		const clockInOutData: TaskClockInOut = {
			dateTime,
			projectTaskId: taskId,
		};

		createClockAppointment(clockInOutData);
		setInsertAppointments((prev) => ({
			...prev,
			[taskId]: null,
		}));
	}, [date, insertAppointments, createClockAppointment]);

	const navigateToDate = useCallback((newDate: Date) => {
		const formattedDate = format(newDate, 'yyyy-MM-dd');
		setSearchParams({ userId, date: formattedDate });
	}, [setSearchParams, userId]);

	const erpClockHoursTableMemo = useMemo(() => (
		<ERPClockHoursTable
			erpClockInOuts={erpClockInOuts}
			erpClockInOutsCount={erpClockInOutsCount}
		/>
	), [erpClockInOuts, erpClockInOutsCount]);

	const { numberOfInconsistencies, hasInconsistencies } = useMemo(() => {
		let inconsistencyCount = 0;
		projects.forEach((project) => project.tasks.forEach(
			(task) => task.tasksClockInOut.forEach((clockInOut) => {
				if (clockInOut.hasInconsistence) {
					inconsistencyCount += 1;
				}
			}),
		));

		return {
			numberOfInconsistencies: inconsistencyCount,
			hasInconsistencies: inconsistencyCount > 0,
		};
	}, [projects]);

	if (loading) {
		return <Loading absolute />;
	}

	return (
		<Box>
			<PageHeader
				title="Apontamentos de projeto"
				subtitle={`Usuário: ${userInfo.name}\nMarca Ponto: ${userInfo.registerHour ? 'Sim' : 'Não'}`}
			/>
			<Paper sx={{ padding: 3, marginBlock: 3 }}>
				<DayCarousel
					onDateChange={navigateToDate}
					title="Consulta de marcações de ponto"
					initialValuesDate={date}
				/>
				{erpClockInOuts.size > 0 ? (
					erpClockHoursTableMemo
				) : (
					<Typography variant="body1" sx={{ my: 2 }}>
						Sem marcações de ponto para o dia selecionado
					</Typography>
				)}
			</Paper>
			<Box>
				{projects.length > 0 && projects.map((project) => (
					<Accordion
						key={project.id}
						defaultExpanded
					>
						<AccordionSummary
							expandIcon={<ExpandMoreIcon />}
							aria-controls={`${project.id}-content`}
							id={`${project.id}-header`}
						>
							<Tooltip
								title={(
									<>
										<Typography color="inherit">Informações do Projeto</Typography>
										<Box>
											<Typography variant="body2">
												Descrição:
												{project.description}
											</Typography>
											<Typography variant="body2">
												Início:
												{formatDateAndHours(project.startDate)}
											</Typography>
											<Typography variant="body2">
												Término:
												{formatDateAndHours(project.endDate)}
											</Typography>
											<Typography variant="body2">
												Cliente:
												{project.client}
											</Typography>
											<Typography variant="body2">
												Loja:
												{project.store}
											</Typography>
											<Typography variant="body2">
												Fase:
												{project.phase}
											</Typography>
											<Typography variant="body2">
												Concluído:
												{project.finished ? 'Sim' : 'Não'}
											</Typography>
										</Box>
									</>
								)}
							>
								<Typography variant="h6" sx={{ lineHeight: 1, flexGrow: 1, cursor: 'help' }}>
									{`${project.code} - ${project.name}`}
								</Typography>
							</Tooltip>
						</AccordionSummary>
						<AccordionDetails>
							<Table size="small" aria-label="purchases">
								<TableHead>
									<TableRow>
										<TableCell sx={{ width: '40%' }}>Tarefa</TableCell>
										<TableCell sx={{ width: '50%' }}>Horários registrados</TableCell>
										<TableCell sx={{ width: '10%', minWidth: '150px' }}>
											<Box sx={{ display: 'flex', alignItems: 'center', gap: '4px' }}>
												<span>Hora</span>
												<AccessTimeIcon />
												{' '}
											</Box>
										</TableCell>
									</TableRow>
								</TableHead>
								<TableBody>
									{project.tasks.map((task) => (
										<TableRow key={task.id}>
											<TableCell>{`${task.code} - ${task.name}`}</TableCell>
											<TableCell>
												<Box sx={{
													display: 'flex',
													alignItems: 'center',
													gap: '16px',
													flexWrap: 'wrap',
												}}
												>
													{task.tasksClockInOut.map((clockInOut) => (
														<Box
															key={clockInOut.id}
															sx={{
																display: 'inline-flex',
																alignItems: 'center',
																backgroundColor: clockInOut.hasInconsistence ? '#FCE4D6' : '',
																':last-child': {
																	marginRight: 0,
																},
															}}
														>
															<TimePicker
																renderInput={(params: TextFieldProps) => (
																	<Tooltip title={clockInOut.hasInconsistence ? 'Horário inconsistente' : ''}>
																		<TextField
																			// eslint-disable-next-line react/jsx-props-no-spreading
																			{...params}
																			InputProps={{ ...params.InputProps, endAdornment: null }}
																			variant="standard"
																			type="time"
																			sx={{ width: 50 }}
																			onKeyDown={(e) => {
																				if (e.key === 'Enter') {
																					e.preventDefault();
																					sendAppointment(
																						task.id,
																						(clockInOut.id as string),
																					);
																				}
																			}}
																		/>
																	</Tooltip>
																)}
																onChange={(newValue) => onChangeAppointment(
																	task.id,
																	newValue,
																	clockInOut.id,
																)}
																value={
																	updateAppointments[(clockInOut.id as string)]
																	|| new Date(clockInOut.dateTime)
																}
															/>
															<IconButton
																size="small"
																disabled={hasApproval}
																onClick={() => sendAppointment(
																	task.id,
																	(clockInOut.id as string),
																)}
															>
																<Tooltip title="Atualizar registro">
																	<TimerIcon />
																</Tooltip>
															</IconButton>
															<IconButton
																size="small"
																disabled={hasApproval}
																onClick={() => removeClockAppointment(clockInOut.id as string)}
																sx={{ padding: '0px', color: 'error.main' }}
															>
																<Tooltip title="Remover registro">
																	<DeleteIcon fontSize="small" />
																</Tooltip>
															</IconButton>
														</Box>
													))}
												</Box>
											</TableCell>
											<TableCell>
												<Box sx={{
													display: 'flex',
													flexDirection: 'row',
													alignItems: 'center',
													gap: 1,
												}}
												>
													<TimePicker
														renderInput={(params: TextFieldProps) => (
															<TextField
																// eslint-disable-next-line react/jsx-props-no-spreading
																{...params}
																InputProps={{ ...params.InputProps, endAdornment: null }}
																variant="standard"
																sx={textFieldStyle}
																disabled={hasApproval}
																onKeyDown={(e) => {
																	if (!hasApproval && e.key === 'Enter') {
																		e.preventDefault();
																		sendNewAppointment(task.id);
																	}
																}}
															/>
														)}
														onChange={(newValue) => onChangeAppointment(
															task.id,
															newValue,
														)}
														value={insertAppointments[(task.id as any)] || null}
													/>
													<Button
														variant="contained"
														disabled={hasApproval}
														onClick={() => sendNewAppointment(task.id)}
														size="small"
														startIcon={<TimerIcon />}
													>
														Registrar
													</Button>
												</Box>
											</TableCell>
										</TableRow>
									))}
								</TableBody>
							</Table>
						</AccordionDetails>
					</Accordion>
				))}
			</Box>
			{projects.length > 0 ? (
				<Box sx={{
					mt: 2,
					display: 'flex',
					justifyContent: 'flex-end',
					gap: 2,
				}}
				>
					{hasApproval && (
						<Box sx={{ width: '100%' }}>
							<Alert severity="warning">
								Os registros já foram aprovados!
							</Alert>
						</Box>
					)}
					{hasInconsistencies && (
						<Box sx={{ width: '100%' }}>
							<Alert severity="warning">
								Há
								{' '}
								{numberOfInconsistencies}
								{' '}
								registro
								{numberOfInconsistencies > 1 ? 's' : ''}
								{' '}
								inconsistente
								{numberOfInconsistencies > 1 ? 's' : ''}
								, é necessário
								{numberOfInconsistencies > 1 ? 'm' : ''}
								{' '}
								editar antes de Aprovar!
							</Alert>
						</Box>
					)}
					<LoadingButton
						size="medium"
						variant="contained"
						color="primary"
						type="submit"
						disabled={hasApproval || hasInconsistencies || projects.length === 0}
						onClick={() => sendApprovalIds(taskClockInOutIds)}
						loading={loading}
					>
						Aprovar
					</LoadingButton>
				</Box>
			) : (
				<Alert severity="warning">
					Não há registros de tarefas para o usuário no dia selecionado!
				</Alert>
			)}
		</Box>
	);
};

export default UserTasksAppointedHours;
